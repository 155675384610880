import downTriangle from '../assets/down-triangle.svg';

export const BaseCourseTypeSelector = function ({ value, onChange }) {
  return (
    <div className="base-selector" cy="type-selector">
      <select
        value={value}
        onChange={(ev) => onChange({ key: 'moduleType', value: ev.target.value })}
      >
        <option value="EL2">EL2</option>
        <option value="LEP">LEP</option>
        <option value="MCQ">MCQ</option>
        <optgroup label="Legacy">
          <option value="ELU">ELU</option>
          <option value="ICH">ICH</option>
          <option value="JIT">JIT</option>
          <option value="RRR">RRR</option>
        </optgroup>
      </select>
      <img src={downTriangle} alt="" />
    </div>
  );
};
