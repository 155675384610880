import React, { useMemo } from 'react';
import { Link, NavLink, useParams } from 'react-router-dom';
import { useChapters } from '../store';
import { useDispatch } from 'react-redux';
import { BaseLoadingSpinner } from '../components';
import { CREATE_NEW_COLLECTION } from '../store/modules/collection';
import { CLEAR_QUESTIONS } from '../store/modules/questions';
import { useBatchMetadata, homeState } from '../store/content/homepage';
import { useAtom } from 'jotai';
import '../components/css/select-search.scss';
import './css/cms-homepage.scss';

const CMSHomepage = () => {
  const { status, type, search, handleSearch, handleType } = useBatchMetadata();
  const [{ data }] = useAtom(homeState);
  const tableHeader = useMemo(
    () => [
      {
        label: 'Name',
        value: 'title'
      },
      {
        label: 'Module ID',
        value: 'moduleId'
      },
      {
        label: 'Locale',
        value: 'locale'
      },
      {
        label: 'Status',
        value: 'status'
      }
    ],
    []
  );
  let params = useParams();
  const { createNewChapter } = useChapters(params.id, params.language);
  const dispatch = useDispatch();

  function handleNewCourse() {
    params.id = null;
    dispatch(CLEAR_QUESTIONS());
    createNewChapter();
    window.history.replaceState({}, '', '/course/new');
  }
  function handleNewCollection() {
    dispatch(CREATE_NEW_COLLECTION());
    window.history.replaceState({}, '', '/collection/new');
  }

  return (
    <div className="cms-homepage">
      <section className="page-content">
        <h2>All courses & collections</h2>
        <div className="table-filters">
          <div className="search relative inline-block">
            <input
              value={search}
              placeholder="Filter by module id or keyword"
              onChange={({ target: { value } }) => handleSearch(value)}
            />
            <i className="absolute">
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
                <path
                  d="M9.9123 1.45095C12.2923 3.49774 12.6923 7.03303 10.8299 9.55987L14.6399 13.3699C14.9521 13.7264 14.9343 14.264 14.5991 14.5991C14.264 14.9343 13.7264 14.9521 13.3699 14.6399L9.55987 10.8299C7.03303 12.6923 3.49774 12.2923 1.45095 9.9123C-0.595849 7.53235 -0.462252 3.97701 1.75738 1.75738C3.97701 -0.462252 7.53235 -0.595849 9.9123 1.45095ZM1.7998 6.00987C1.7998 8.32947 3.68021 10.2099 5.9998 10.2099C7.11371 10.2099 8.182 9.76737 8.96965 8.97972C9.75731 8.19207 10.1998 7.12378 10.1998 6.00987C10.1998 3.69028 8.3194 1.80987 5.9998 1.80987C3.68021 1.80987 1.7998 3.69028 1.7998 6.00987Z"
                  fill="#2A6EBB"
                />
              </svg>
            </i>
          </div>
        </div>
        <div className="type-selector">
          <button
            className={type === 'course' ? 'selected' : ''}
            onClick={() => {
              handleType('course');
            }}>
            Courses
          </button>
          <button
            className={type === 'collection' ? 'selected' : ''}
            onClick={() => {
              handleType('collection');
            }}>
            Collections
          </button>
        </div>
        <ul className="table-header">
          {tableHeader.map(({ label, value }) => (
            <li className={value} key={value}>
              {label}
            </li>
          ))}
        </ul>
        {status === 'loading' ? (
          <div className="loading-spinner">
            <BaseLoadingSpinner />
          </div>
        ) : (
          <div className="table-body">
            <div>
              {data
                .filter((obj) => {
                  const id = obj.moduleId || obj.id;
                  return !search
                    ? true
                    : id.includes(search) || obj.title.toLowerCase().includes(search.toLowerCase());
                })
                .slice(0, 100)
                .map((obj) => (
                  <ul key={obj.id + obj.title}>
                    {tableHeader.map(({ value }) => (
                      <li className={value} key={value}>
                        {value === 'title' ? (
                          <Link to={`/${type}/${obj.id || obj.moduleId}/${obj.locale}`}>
                            {obj[value]}
                          </Link>
                        ) : (
                          <span>{obj[value] || obj.id}</span>
                        )}
                      </li>
                    ))}
                  </ul>
                ))}
            </div>
          </div>
        )}
        {type === 'course' && (
          <NavLink className="add-collection fixed" to="/course/new">
            <button onClick={handleNewCourse} className="blue-btn">
              Add new course
            </button>
          </NavLink>
        )}
        {type === 'collection' && (
          <NavLink className="add-collection fixed" to="/collection/new">
            <button onClick={handleNewCollection} className="blue-btn">
              Add collection
            </button>
          </NavLink>
        )}
      </section>
    </div>
  );
};

export default CMSHomepage;
