import './css/chapters-management.scss';
import { useState } from 'react';
import { BaseModal } from './BaseModal';
import { BaseAddNewButton, BaseDeleteButton, ConfirmationPopup } from '../components';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_CHAPTER, DELETE_LAST_CHAPTER, UPDATE_CHAPTER } from '../store/modules/chapters';

export const ChaptersManagement = function () {
  const chapters = useSelector((state) => state.chapters.data);
  const dispatch = useDispatch();
  const [isBaseModal, setBaseModal] = useState(false);
  const deletionConfirmationMsg = `Are you sure you want to delete the <br /> last chapter?
                              <span><br/>Remember to save before you leave</span>`;

  function addChapter () {
    dispatch(ADD_CHAPTER());
  }

  function handleChapterUpdate (ev, id) {
    dispatch(UPDATE_CHAPTER({ value: ev.target.value, field: ev.target.id, id }));
  }

  function promptDeleteQuestion () {
    setBaseModal(true);
  }

  function handleConfirmDeletion () {
    dispatch(DELETE_LAST_CHAPTER());
    setBaseModal(false);
  }

  function handleDecline () {
    setBaseModal(false);
  }

  return (
    <section
      id="chapters"
      cy="chapters-group"
      className="module-management-chapters content-wrapper"
    >
      {isBaseModal && (
        <BaseModal>
          <ConfirmationPopup
            msg={deletionConfirmationMsg}
            text={{ yes: 'Delete', no: 'Cancel' }}
            handleConfirm={handleConfirmDeletion}
            handleDecline={handleDecline}
          />
        </BaseModal>
      )}
      <div className="chapters">
        <h2>Chapters</h2>

        {chapters?.map(({ id, title, url, duration }, idx) => (
          <div className="chapter-details" cy="chapter-details" key={id}>
            <div className="flex align-end">
              <div className="number-title">
                <b>No.</b>
                <span className="flex align-center">{idx + 1}</span>
              </div>
              <div className="title-title">
                <b>Title</b>
                <input
                  id="title"
                  type="text"
                  value={title}
                  onChange={(e) => handleChapterUpdate(e, id)}
                  placeholder="Title"
                  cy={`chapter-${idx + 1}-title`}
                />
              </div>
              <div className="elucidat-title">
                <b>Elucidat URL</b>
                <input
                  id="url"
                  type="text"
                  value={url}
                  onChange={(e) => handleChapterUpdate(e, id)}
                  placeholder="URL"
                  cy={`chapter-${idx + 1}-url`}
                />
              </div>
              <div className="mins-title">
                <b>Mins</b>
                <input
                  id="duration"
                  type="number"
                  min="0"
                  value={duration ?? ''}
                  onChange={(e) => handleChapterUpdate(e, id)}
                  placeholder=""
                  cy={`chapter-${idx + 1}-duration`}
                />
              </div>
            </div>
          </div>
        ))}

        <div className="flex flex-between">
          <BaseAddNewButton cy="add-chapter" text="Add another chapter" onClick={addChapter} />
          {chapters?.length > 0 && (
            <BaseDeleteButton
              cy="delete-chapter"
              text="Delete last chapter"
              onClick={promptDeleteQuestion}
            />
          )}
        </div>
      </div>
    </section>
  );
};
