import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Button
} from '@material-ui/core';
import { ACCREDITATIONS_URL, ORGANISATIONS_URL } from '../../utils/Constants';

const Organisation = () => {
  const [accreditations, setAccreditations] = useState(null);
  const [organisation, setOrganisation] = useState({});

  const orgId = useParams().id;

  useEffect(() => {
    fetch(ACCREDITATIONS_URL + '?organisationId=' + orgId)
      .then((response) => response.json())
      .then((json) => setAccreditations(json))
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    fetch(ORGANISATIONS_URL + '/' + orgId)
      .then((response) => response.json())
      .then((json) => setOrganisation(json))
      .catch((err) => console.error(err));
  }, []);

  return (
    <Grid container justifyContent="flex-start" className="view-all">
      <Grid container>
        <Typography variant="h2" component="h2">
          Organisation Details
        </Typography>
      </Grid>
      <Grid container>
        <p>
          <span>Id:</span> {orgId}
        </p>
      </Grid>
      <Grid container>
        <p>
          <span>Name:</span> {organisation.name}
        </p>
      </Grid>
      <Grid container>
        <p>
          <span>Statement:</span> {organisation.organisationStatement}
        </p>
      </Grid>
      <Grid container>
        <p>
          <span>Status:</span> {organisation.isActive ? 'ACTIVE' : 'INACTIVE'}
        </p>
      </Grid>
      <Grid className="organisation">
        <Grid container justifyContent="flex-start">
          <h3>Accreditations</h3>
        </Grid>
        <TableContainer style={{ maxHeight: 400, width: '96%' }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Minutes</TableCell>
                <TableCell>Points</TableCell>
                <TableCell>Accreditation statement</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accreditations &&
                accreditations.map((accreditation) => {
                  return (
                    <TableRow key={accreditation.id}>
                      <TableCell>{accreditation.cpdMinutes}</TableCell>
                      <TableCell>{accreditation.cpdPoints}</TableCell>
                      <TableCell>{accreditation.accreditationStatement}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Link
        to={{
          pathname: '/new-accreditation',
          search: `organisationId=${orgId}`
        }}
      >
        <Button variant="contained" className="primary-button">
          Add Accreditation
        </Button>
      </Link>
    </Grid>
  );
};

export default Organisation;
