import './css/duration-input.scss';

export const DurationInput = function ({ name, cy, label, value = '', onChange }) {
  return (
    <label htmlFor={name} cy={cy || name} className="duration-input">
      <input
        type="text"
        id={name}
        value={value}
        onChange={(ev) => onChange({ key: name, value: ev.target.value })}
      />
      <span>{label || name}</span>
    </label>
  );
};
