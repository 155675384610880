import { Auth } from 'aws-amplify';

export const IMAGES_BASE_URL = process.env.REACT_APP_IMAGES_CLOUDFRONT_URL;

export const buildImageUrl = (imageName, locale = 'en-gb') => {
  return `${IMAGES_BASE_URL}/${locale}/${imageName}`;
};

export const getJwtToken = function () {
  return Auth.currentSession().then(({ idToken: { jwtToken } }) => jwtToken);
};

/* for courses, we need to format the date to "Month date, year" e.g.: "Dec 5, 2022 */
export const formatDateToLastUpdated = function (value = new Date()) {
  const d = new Date(value);
  const month = d.toLocaleString('default', { month: 'short' }).slice(0, 3);
  return `${month} ${d.getDate()}, ${d.getFullYear()}`;
};

export const langSwitch = function (lang) {
  switch (lang) {
    case 'en_GB':
      return 'en-gb';
    default:
      return 'en-gb';
  }
};
