import './css/base-input.scss';

export const BaseInput = function ({
  name,
  type,
  cy,
  label,
  value = '',
  placeholder = '',
  onInput,
  classes = ''
}) {
  return (
    <label htmlFor={name} className={`base-input ${classes}`} cy={cy || name}>
      <span>{label}</span>
      <br />
      <input
        className="w100"
        type={type || 'text'}
        value={value}
        onInput={(ev) => onInput({ key: name, value: ev.target.value })}
        placeholder={placeholder}
      />
    </label>
  );
};
