import { useDispatch, useSelector } from 'react-redux';
import { BaseChipsGroup, BaseImageUpload, BaseTextarea, DurationInput } from './';
import {
  HANDLE_DURATION,
  SET_COLLECTION_PROP,
  HANDLE_CHIP_SELECTION
} from '../store/modules/collection';
import './css/collection-information.scss';
import { updateCollectionImg } from '../store/api';
import { audience, specialties as specialtiesList } from '../store/modules/collection';

export const CollectionInformation = function () {
  const { duration, description, homepageImage, partnersLogo, targetAudiences, category } =
    useSelector((state) => state.collection.data);
  const dispatch = useDispatch();

  function handleInput (obj) {
    dispatch(SET_COLLECTION_PROP(obj));
  }

  function handleDuration (obj) {
    dispatch(HANDLE_DURATION(obj));
  }

  async function handleImage ({ key, action, imageName, formData }) {
    switch (action) {
      case 'upload':
        dispatch(
          SET_COLLECTION_PROP({
            key,
            value: key === 'partnersLogo' ? [imageName] : imageName
          })
        );
        await updateCollectionImg(formData);
        break;
      case 'delete':
        dispatch(
          SET_COLLECTION_PROP({
            key,
            value: key === 'partnersLogo' ? null : ''
          })
        );
        break;
    }
  }

  function handleChipSelection (obj) {
    dispatch(HANDLE_CHIP_SELECTION(obj));
  }

  return (
    <section className="collection-information" id="collection-information">
      <h2>Collection information</h2>

      <div className="duration-options">
        <b>Duration</b>
        <DurationInput name="hours" value={duration?.hours} onChange={handleDuration} />
        <DurationInput name="minutes" value={duration?.minutes} onChange={handleDuration} />
      </div>

      <BaseTextarea
        name="description"
        label="Description"
        value={description}
        onInput={handleInput}
      />

      <BaseImageUpload
        name="homepageImage"
        label="Homepage image"
        imageName={homepageImage}
        handleImage={handleImage}
      />

      <BaseChipsGroup
        name="targetAudiences"
        label="Target audiences"
        options={audience}
        selected={targetAudiences}
        onSelect={handleChipSelection}
      />

      <BaseChipsGroup
        name="category"
        label="Specialties"
        options={specialtiesList}
        selected={category}
        onSelect={handleChipSelection}
      />

      <BaseImageUpload
        name="partnersLogo"
        label="Partner logo"
        imageName={partnersLogo && partnersLogo[0]}
        handleImage={handleImage}
      />
    </section>
  );
};
