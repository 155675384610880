import close from '../assets/close-x-icon.svg';
import { BaseModal } from './BaseModal';

export const AccreditationModal = function ({
  handleClose,
  children
}) {

  return (
    <BaseModal>
      <div className='modal modal-size' >
        <div className='modal-header'>
          <button onClick={handleClose}>
            <img src={close} />
          </button>
        </div>
        <div className='modal-body'>
          {children}
        </div>
        <div className='modal-footer'>
        </div>
      </div>
    </BaseModal>
  );
};