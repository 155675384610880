import linkIcon from '../assets/link-external.svg';
import {BaseButton} from './BaseButton';
import './css/base-side-menu.scss';
import { useEffect } from 'react';

export const scrollToSection = function (section = '') {
  const id = section.toLowerCase().split(' ').join('-');
  const top = document.getElementById(id).offsetTop - 80;
  window.scrollTo({
    top,
    behavior: 'smooth'
  });
};

export const BaseSideMenu = function ({
  quickLinks = {},
  actions = {},
  handleAction,
  handleSave,
  status
}) {
  useEffect(() => {
    // we add a short delay to read the logo elm position
    setTimeout(() => {
      resize();
    }, 100);
    document.addEventListener('resize', resize, { passive: true });
    window.onresize = resize;
    return () => {
      document.removeEventListener('resize', resize);
    };
  }, []);

  function resize () {
    const leftLogo = document.querySelector('.brand-logo').getBoundingClientRect().left;
    document.querySelector('.base-side-menu').style.left = `${leftLogo}px`;
  }

  function handleSaving () {
    handleSave();
  }

  const SaveStatus = () => {
    switch (status) {
      case 'saving':
        return 'Saving';
      case 'success':
        return `Saved <i class="inline-block" style="transform: translate(7px, 0);">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.79 3.78959L5.5 11.0896L2.71 8.28959C2.31788 7.89746 1.68212 7.89746 1.29 8.28959C0.89788 8.68171 0.89788 9.31746 1.29 9.70959L4.79 13.2096C5.18004 13.5973 5.80996 13.5973 6.2 13.2096L14.2 5.20959C14.5921 4.82023 14.5944 4.18671 14.205 3.79459C13.8156 3.40246 13.1821 3.40023 12.79 3.78959Z"
                fill="#ffffff"/>
            </svg>
          </i>`;
      case 'error':
        return 'Save error';
      default:
        return 'Save to live';
    }
  };

  return (
    <div className="base-side-menu fixed">
      <div>
        <h3 cy="quick-links-title">
          <b>{quickLinks.title}</b>
        </h3>
        <ul cy="quick-links">
          {quickLinks.options?.map((item) => (
            <li key={item} onClick={() => scrollToSection(item)}>
              {item}
            </li>
          ))}
        </ul>
      </div>

      <div>
        <h3>
          <b>{actions.title}</b>
        </h3>
        <ul cy="actions">
          {actions.options?.map(({ name, action, cy, icon }) => (
            <li key={name} cy={cy} onClick={() => handleAction(action)}>
              {name}
              {icon && <img src={linkIcon} alt="external link" />}
            </li>
          ))}
        </ul>
        <BaseButton name="save" cy="save-course-btn" onClick={handleSaving}>
          <SaveStatus />
        </BaseButton>
      </div>
    </div>
  );
};
