import { buildImageUrl } from '../store/utils';
import { useState } from 'react';
import './css/base-image-upload.scss';

export const BaseImageUpload = function ({ name, label, imageName, handleImage }) {
  const [isImgPreview, setIsImgPreview] = useState(false);
  const formData = new FormData();

  function handleUpload (input) {
    setIsImgPreview(true);
    formData.append('upload-image', input.target.files[0]);
    handleImage({ key: name, action: 'upload', imageName: input.target.files[0].name, formData });
    previewImage(input);
  }

  function handleDelete () {
    handleImage({ key: name, action: 'delete' });
    setIsImgPreview(false);
  }

  function previewImage (input) {
    const reader = new FileReader();
    reader.readAsDataURL(input.target.files[0]);
    reader.onload = function (e) {
      document.getElementById(name).setAttribute('src', e.target.result);
    };
  }

  return (
    <div className="base-image-upload m-t-24 block flex flex-between flex-max" name={name}>
      <div className="relative" cy={name}>
        <b className="inline-block m-b-8">{label}</b>
        <br />
        {imageName && !isImgPreview && (
          <img className="w100" src={buildImageUrl(imageName)} alt="" />
        )}
        {!imageName && (
          <label className={`${isImgPreview ? 'hide' : ''} flex-center pointer`} cy="upload-img">
            Upload image
            <input className="absolute left0 top0" onChange={handleUpload} type="file" />
          </label>
        )}
        <img id={name} className={`img-preview ${isImgPreview ? '' : 'hide'}`} />
        <br />
        {(isImgPreview || imageName) && (
          <div className="text-right">
            <span className="pointer" cy="delete-img" onClick={handleDelete}>
              Delete
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
