import { MultiSelect } from 'primereact/multiselect';
import { useDispatch, useSelector } from 'react-redux';
import {
  UPDATE_COURSE,
  UPDATE_ADDITIONAL_SUBSCRIPTION,
  UPDATE_SUBSCRIPTIONS
} from '../store/modules/course';
import {
  BaseCourseTypeSelector,
  StatusSelector,
  BaseChip,
  LastUpdated,
  CopyToClipboard
} from './index';
import { formatDateToLastUpdated } from '../store/utils';
import './css/key-details-section.scss';

export const CourseKeyDetails = function () {
  const dispatch = useDispatch();
  const {
    subscriptionsList,
    additionalSubscriptionsList,
    data: { title, moduleType, status, locale, dateUpdated = new Date(), accessResources, id }
  } = useSelector((state) => state.course);

  const additionalSubscriptionsGroup = [
    {
      label: 'Journal',
      items: additionalSubscriptionsList.slice(0, 8)
    },
    {
      label: 'India course',
      items: additionalSubscriptionsList.slice(8, 48)
    },
    {
      label: 'Customer',
      items: additionalSubscriptionsList.slice(48, 52)
    },
    {
      label: 'Collection/Topic',
      items: additionalSubscriptionsList.slice(52, 56)
    },
    {
      label: 'China',
      items: additionalSubscriptionsList.slice(56, 57)
    },
    {
      label: 'BMA',
      items: additionalSubscriptionsList.slice(57, 62)
    },
    {
      label: 'Research to Publication',
      items: additionalSubscriptionsList.slice(62, 63)
    }
  ];

  function handleUpdate({ key, value }) {
    dispatch(UPDATE_COURSE({ key, value }));
  }

  function handleTypeUpdate({ key, value }) {
    dispatch(UPDATE_COURSE({ key, value }));
    if (value === 'MCQ') {
      dispatch(UPDATE_COURSE({ key: 'doi', value: '' }));
    }
  }

  function handleLastUpdated({ key, value }) {
    // the BE requested date format is "Month (short) date, full year" like Nov 16, 2022 (for example)
    dispatch(UPDATE_COURSE({ key, value: formatDateToLastUpdated(value) }));
  }

  function handleAdditional(arr) {
    dispatch(UPDATE_ADDITIONAL_SUBSCRIPTION(arr));
  }

  function handleUpdateSubscriptions(arr) {
    dispatch(UPDATE_SUBSCRIPTIONS(arr));
  }

  return (
    <section className="key-details-section" id="key-details" cy="course-key-details">
      <header className="flex flex-between">
        <h2>Key details</h2>
        <div className="flex-center">
          <BaseCourseTypeSelector value={moduleType} onChange={handleTypeUpdate} />

          <StatusSelector title status={status} onChange={handleUpdate} />
        </div>
      </header>

      <div>
        <label htmlFor="title">
          Course title
          <br />
          <textarea
            className="w100"
            cy="course-title"
            id="title"
            value={title}
            onInput={(ev) => handleUpdate({ key: 'title', value: ev.target.value })}
            placeholder="course title"
          />
        </label>
        <span>Locale: {locale}</span>
      </div>

      <div className="unique-updated flex m-t-24">
        <div className="m-r-24">
          <label htmlFor="unique">
            Unique ID <br />
            <input
              type="text"
              cy="course-id"
              id="unique"
              value={id || ''}
              disabled
              onInput={() => {}}
            />
          </label>
          <CopyToClipboard />
        </div>
        <LastUpdated dateUpdated={dateUpdated} onChange={handleLastUpdated} />
      </div>

      <div className="subscriptions-chips m-t-24" cy="subscriptions">
        <b>Subscriptions</b>
        <br />
        {subscriptionsList?.map(({ displayName, id }) => (
          <BaseChip
            label={displayName}
            id={id}
            key={id}
            selected={accessResources?.map((o) => o.id).some((val) => +val === +id)}
            section="keyDetails"
            onClick={handleUpdateSubscriptions}
          />
        ))}
      </div>

      <div id="bmj-journals-subscriptions" className="relative m-t-24">
        <label htmlFor="additional" cy="additional-subscriptions">
          Additional subscriptions <br />
          <MultiSelect value={accessResources?.filter(({id}) => +id > 2)}
                       options={additionalSubscriptionsGroup}
                       onChange={(e) => handleAdditional(e.value)}
                       optionLabel="displayName"
                       optionGroupLabel="label"
                       optionGroupChildren="items"
                       placeholder="Search all other subscriptions"
                       display="chip"
                       filter
                       resetFilterOnHide />
        </label>
      </div>
    </section>
  );
};
