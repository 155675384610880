import { useDispatch, useSelector } from 'react-redux';
import { BaseTextarea } from './index';
import { UPDATE_COURSE } from '../store/modules/course';
import './css/course-acknowledgement.scss';

export const CourseAcknowledgements = function () {
  const acknowledgements = useSelector((state) => state.course.data.acknowledgements);
  const dispatch = useDispatch();

  function handleInput({ key, value }) {
    dispatch(UPDATE_COURSE({ key, value }));
  }

  return (
    <section id="acknowledgement" cy="course-acknowledgement" className="course-acknowledgement">
      <h2>Acknowledgements</h2>
      <div className="acknowledgement-group" cy="acknowledgement-group">
        <BaseTextarea
          name="acknowledgements"
          label={'Acknowledgement <span>(optional)</span>'}
          value={acknowledgements}
          onInput={handleInput}
        />
      </div>
    </section>
  );
};
