import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  ADD_ANSWER,
  ADD_NEW_QUESTION,
  DELETE_LAST_ANSWER,
  DELETE_LAST_QUESTION,
  get_questions,
  SELECT_CORRECT_ANSWER,
  SET_PASSMARK,
  SET_QUESTION_PROP,
  UPDATE_ANSWER_TEXT
} from '../store/modules/questions';
import {
  BaseAnswer,
  BaseAddNewButton,
  BaseInput,
  RichTextEditor,
  BaseSelector,
  BaseDeleteButton
} from './';
import plus from '../assets/plus.svg';
import minus from '../assets/minus.svg';
import './css/course-questions.scss';

const questionHeight = 80;
/* we set the answer group height because when we Delete last
 answer, the height of the question group does not auto shrink,
 so we manually subtract the answer height from the full height */
const answerHeight = 106;

/* How we show/hide all questions?
  fn to show hide all question with vanilla js using the class node array to loop and
  alternate the individual question button text to +/- and expand the question items visibility
  using the .scrollHeight js method */
function toggleShowHideAll (action) {
  const list = document.getElementsByClassName('base-question');
  Array.from(list).forEach((el) => {
    el.style.height = action === 'show' ? `${el.scrollHeight}px` : `${questionHeight}px`;
    const buttonImg = el.querySelector('header button img');
    buttonImg.setAttribute('src', action === 'show' ? minus : plus);
  });
}

/* How we expand/collapse a single question?
  fn to expand/collapse a single question using vanilla js and .scrollHeight method
  Note: it traverses the DOM in up direction from +/- button element to the parent element
  so this fn is dependent on the button element position in the HTML structure */
function toggleExpandQuestion (ev) {
  const buttonImg = ev.target.parentElement.querySelector('img');
  const isCollapsed = buttonImg.getAttribute('src').includes('plus');
  const questionElm = ev.target.parentNode.parentNode;
  questionElm.style.height = isCollapsed ? `${questionElm.scrollHeight}px` : `${questionHeight}px`;
  buttonImg.setAttribute('src', isCollapsed ? minus : plus);
}

/* How we display the chapter value in a question chapter selector field?
  each question object has a tag key (property) of which value represents a chapter's id,
  and we loop through chapters to get the title string value to inject into the BaseSelector
  Related chapter field */
function getChapterTitleByQtag (chapters = [], tag = '') {
  let title = '';
  tag &&
    chapters.forEach((obj) => {
      obj.id?.toString() === tag.toString() && (title = obj.title);
    });
  return title;
}

export const CourseQuestions = function () {
  const params = useParams();
  const { questions, passmark } = useSelector((state) => state.questions.data);

  const { moduleType } = useSelector((state) => state.course.data);
  const chapters = useSelector((state) => state.chapters.data);
  const chaptersList = [...chapters.map((o) => o.title), 'no chapter'];
  const dispatch = useDispatch();

  useEffect(() => {
    // this one should trigger if the course is not new
    // if the route contains /new then do not trigger this
    !window.location.pathname.includes('/new') && dispatch(get_questions(params));
  }, []);

  /* How we select a Related chapter?
   * we need to update the tag property of the question in the store, so we get the value from the BaseSelector component
   * and we dispatch the proper method to update this key */
  function handleChapterSelection ({ id, key, value }) {
    // id = questionId, key = tag, value = selected value from <select> tag options
    const chapterId = chapters.reduce((acc, curr) => {
      value === curr.title && (acc = curr.id.toString());
      return acc;
    }, '');
    dispatch(SET_QUESTION_PROP({ id, key, value: chapterId }));
  }

  function handleAddAnswer ({ id, index }) {
    dispatch(ADD_ANSWER({ id }));
    /* we also need to update the UI - the question section height */
    const questionElm = document.getElementById(`question-${index}`);
    /* we need to wait for the UI to update and then to request the height of the question container */
    setTimeout(() => {
      questionElm.style.height = `${questionElm.scrollHeight}px`;
    }, 0);
  }

  function handleDeleteAnswer ({ id, index }) {
    dispatch(DELETE_LAST_ANSWER({ id }));
    /* we need to update the UI - the question section height */
    const questionElm = document.getElementById(`question-${index}`);
    questionElm.style.height = `${questionElm.scrollHeight - answerHeight}px`;
  }

  return (
    <section className="course-questions" cy="course-questions" id="questions">
      <header className="flex flex-between align-center">
        <div>
          <h2 cy="q-title">Questions</h2>
          <div>
            <button cy="show-all" onClick={() => toggleShowHideAll('show')}>
              Show all
            </button>
            <button cy="hide-all" onClick={() => toggleShowHideAll('hide')}>
              Hide all
            </button>
          </div>
        </div>

        {/* PASSMARK */}
        <label className="flex flex-center" htmlFor="passmark">
          <b>Passmark (%)</b>
          <input
            type="number"
            className="text-center"
            id="passmark"
            max="100"
            cy="passmark"
            value={passmark}
            onChange={(ev) => dispatch(SET_PASSMARK(ev.target.value))}
          />
        </label>
      </header>

      {questions.map(
        ({ id, tag, questionIntro, question, options, answer, explanation }, index) => (
          <div className="base-question m-t-24" cy="question" id={`question-${index}`} key={index}>
            <header className="flex flex-between align-center">
              <h3 className="pointer" cy="question-title" onClick={toggleExpandQuestion}>
                Question {index + 1}
              </h3>
              <button
                cy="toggle-expand"
                className="no-border bg-transparent"
                onClick={toggleExpandQuestion}
              >
                <img className="click-disabled" src={plus} alt="" />
              </button>
            </header>

            {/*Related chapter*/}
            {moduleType !== 'MCQ' && (
              <BaseSelector
                value={getChapterTitleByQtag(chapters, tag)}
                label="Related chapter"
                name="tag"
                cy="related-chapter"
                handleUpdate={(obj) => handleChapterSelection({ id, ...obj })}
                list={chaptersList}
              />
            )}

            {/* Context (optional} */}
            <RichTextEditor
              title="Context"
              name="questionIntro"
              value={questionIntro}
              questionId={id}
              onChange={(obj) => dispatch(SET_QUESTION_PROP({ key: 'questionIntro', ...obj }))}
              large
              optional
            />

            {/* Question field */}
            <BaseInput
              name="question"
              cy="question-field"
              onInput={(obj) => dispatch(SET_QUESTION_PROP({ id, ...obj }))}
              value={question.replace(/<[^>]+>/g, '')}
              label="Question"
            />

            {/* Answers */}
            <div>
              {options?.map(({ name, value }, idx) => (
                <BaseAnswer
                  name={`answer${index}`}
                  cy="answer-combo"
                  key={idx}
                  index={idx + 1}
                  value={name}
                  answer={value}
                  correct={answer}
                  onInput={(obj) => dispatch(UPDATE_ANSWER_TEXT({ id, index: idx, ...obj }))}
                  onSelect={(obj) => dispatch(SELECT_CORRECT_ANSWER({ id, ...obj }))}
                />
              ))}

              <div className="flex flex-between m-t-16">
                <BaseAddNewButton
                  text="Add another answer"
                  cy="add-answer"
                  onClick={() => handleAddAnswer({ id, index })}
                />
                {/* Hide Delete last answer button if there are no answers */}
                {options.length ? (
                  <BaseDeleteButton
                    text="Delete last answer"
                    cy="delete-last-answer"
                    onClick={() => handleDeleteAnswer({ id, index })}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>

            {/* Context (optional} */}
            <RichTextEditor
              title="Answer explanation"
              name="explanation"
              cy="answer-explanation"
              value={explanation}
              questionId={id}
              onChange={(obj) => dispatch(SET_QUESTION_PROP({ key: 'explanation', ...obj }))}
              large
            />
          </div>
        )
      )}

      <div className="flex flex-between m-t-24">
        <BaseAddNewButton
          name="add-new-question"
          text="Add another question"
          onClick={() => dispatch(ADD_NEW_QUESTION())}
        />
        <BaseDeleteButton
          name="delete-last-question"
          text="Delete last question"
          onClick={() => dispatch(DELETE_LAST_QUESTION())}
        />
      </div>
    </section>
  );
};
