import './App.scss';
import './css/typography.scss';
import './css/helper-classes.scss';
import './css/base.scss';
import { Helmet } from 'react-helmet';
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ModuleList from './pages/Modules/ModuleList';
import BatchAccreditation from './pages/BatchAccreditation/BatchAccreditation';
import OrganisationList from './pages/Organisation/OrganisationList';
import Organisation from './pages/Organisation/Organisation';
import NewOrganisation from './pages/Organisation/NewOrganisation';
import NewAccreditation from './pages/Accreditation/NewAccreditation';
import CMSHomepage from './pages/CMSHomepage';
import CollectionEdit from './pages/CollectionEdit';
import CourseEdit from './pages/CourseEdit';
import Reviews from './pages/Reviews';
import { AmplifyAuthenticator, AmplifySignUp } from '@aws-amplify/ui-react';
import { MainTopNav } from './components';

const App = () => {
  return (
    <AmplifyAuthenticator>
      <AmplifySignUp
        slot="sign-up"
        formFields={[
          { type: 'username' },
          { type: 'email' },
          {
            type: 'password',
            label: 'Password',
            placeholder: 'Enter your password'
          }
        ]}
      />
      <Router>
        <div className="main-app">
          <Helmet>
            <title>Learning admin tool</title>
            <meta name="description" content="This is a tool to make any admin changes" />
          </Helmet>
          <MainTopNav />
          <Switch>
            <Route path="/" exact>
              <CMSHomepage />
            </Route>

            <Route path="/courses">
              <ModuleList />
            </Route>
            <Route path="/course/new">
              <CourseEdit />
            </Route>
            <Route path="/course/:id/:language">
              <CourseEdit />
            </Route>
            <Route path="/course/:id">
              <CourseEdit />
            </Route>
            <Route path="/batch-accreditation">
              <BatchAccreditation />
            </Route>
            <Route path="/reviews">
              <Reviews />
            </Route>
            <Route path="/organisations">
              <OrganisationList />
            </Route>
            <Route path="/new-organisation">
              <NewOrganisation />
            </Route>
            <Route path="/new-accreditation">
              <NewAccreditation />
            </Route>
            <Route path="/organisation/:id">
              <Organisation />
            </Route>
            <Route path="/collection/:collectionId/:language">
              <CollectionEdit />
            </Route>
            <Route path="/collection/new">
              <CollectionEdit />
            </Route>
            <Route path="/collection/:collectionId">
              <CollectionEdit />
            </Route>
          </Switch>
        </div>
      </Router>
    </AmplifyAuthenticator>
  );
};

export default App;
