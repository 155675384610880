import './css/base-chip.scss';

export const BaseChip = function ({ section, label, id, selected, onClick }) {
  return (
    <span
      className={`base-chip pointer inline-block ${selected ? 'base-chip-selected' : ''}`}
      onClick={() => onClick({ section, id })}
    >
      {label}
    </span>
  );
};
