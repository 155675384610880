export const ReviewsHeader = function ({
  checked = false,
  handleCheck
}) {

  const columns = [
    { id: 'dateAdded', text: 'Date Added' },
    { id: 'status', text: 'Status' },
    { id: 'courseId', text: 'Course Id' },
    { id: 'courseTitle', text: 'Course Title' },
    { id: 'review', text: 'Review' },
    { id: 'editorComments', text: 'Editor Comments' },
    { id: 'icsId', text: 'Reviewer ICS Id' },
    { id: 'reviewerEmail', text: 'Reviewer Email' }
  ];

  return (
    <div className='reviews-header'>
      <div id='select'>
        <span className='select'>
          <input
            type='checkbox'
            checked={checked}
            value='selectAll'
            onChange={handleCheck}
          />
        </span>
      </div>
      {columns.map((column) => {
        return (
          <div
            key={column.id}
            id={column.id}
          >
            {column.text}
          </div>
        );
      })}
    </div>
  );
};