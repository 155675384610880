export const LEARNING_BACKEND_URL = process.env.REACT_APP_LEARNING_BACKEND_URL;
export const ACCREDITATIONS_API_URL = process.env.REACT_APP_ACCREDITATIONS_API_URL;
export const ACCREDITATIONS_URL = `${ACCREDITATIONS_API_URL}/accreditations`;
export const ORGANISATIONS_URL = `${ACCREDITATIONS_API_URL}/organisations`;
export const CREATE_MULTIPLE_ACCREDITED_RESOURCES_URL = `${ACCREDITATIONS_API_URL}/accredited-resources/modules`;
export const API_GATEWAY_URL = process.env.REACT_APP_API_GATEWAY_URL;
export const CONTENT_API_URL = process.env.REACT_APP_LEARNING_CONTENT_API_URL;
export const PRE_TEST_QUESTIONS_URL = `${API_GATEWAY_URL}/pre-test-questions/{courseId}/{courseLanguage}`;
export const CHAPTERS_URL = `${API_GATEWAY_URL}/chapters/{courseId}/{courseLanguage}`;
export const MODULE_MAPPING_URL = `${API_GATEWAY_URL}/module-mapping`;
export const DOI_URL = `${API_GATEWAY_URL}/module-mapping/external-id?moduleId={courseId}&locale={courseLanguage}`;
export const CONTENT_METADATA_URL = `${API_GATEWAY_URL}/metadata/{id}/{language}`;
export const SAVE_CONTENT_METADATA_URL = `${API_GATEWAY_URL}/metadata`;
export const SEARCH_API_URL = `${API_GATEWAY_URL}/search`;
export const METADATA_URL = `${API_GATEWAY_URL}/metadata`;
export const COURSES_METADATA_URL = `${LEARNING_BACKEND_URL}/collections/courses/metadata`;
export const LIVE_COURSES_ID_URL = `${API_GATEWAY_URL}/metadata/module-ids?locale={locale}`;
export const REVIEWS_URL = `${API_GATEWAY_URL}/reviews-new`;
export const LIVE_COURSE_PAGE_BASE_URL = 'http://new-learning.bmj.com/course/';