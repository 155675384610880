import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BaseLoadingSpinner,
  BaseModal,
  ReviewsComment,
  ReviewsEntry,
  ReviewsFilter,
  ReviewsHeader,
  ReviewsModal
} from '../components';
import BasePaginationNumbers from '../components/BasePaginationNumbers';
import { ServerErrorModal } from '../components/ServerErrorModal';
import {
  RESET_SELECTION,
  SET_STATE,
  TOGGLE_SELECTED,
  fetch_reviews,
  update_review_comment,
  update_review_status
} from '../store/modules/reviews';
import {
  buildStatusUpdates,
  formatFilterDate
} from '../utils/reviewsUtils';
import './css/reviews.scss';

const Reviews = function () {
  const dispatch = useDispatch();
  const {
    pagedReviews,
    totalReviewsCount,
    pageNumber,
    pageSize,
    reviews,
    selected,
    updateStatus,
    filter,
    editorComment,
    status: fetchStatus
  } = useSelector((state) => state.reviews);
  const [commentModal, setCommentModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [statusUpdateModal, setStatusUpdateModal] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    dispatch(fetch_reviews(pageNumber, pageSize));
  }, []);

  function handlePageChange (newPage, currReviews = []) {
    // dispatch(handlePagination(newPage, currReviews));
    console.log('new PAge here', newPage);
    dispatch(
      SET_STATE({
        key: 'pageNumber',
        value: newPage
      })
    );
    
    let reviewArr = currReviews.length > 0 ? currReviews : reviews;
    let startIndex = (newPage - 1) * pageSize;
    let endIndex = (newPage * pageSize) > reviewArr.length ? reviewArr.length : (newPage * pageSize);
    let teRev = reviewArr.slice(startIndex , endIndex);
    console.log('paged review here ', teRev);
  
    dispatch(SET_STATE({
      key: 'pagedReviews',
      value: reviewArr.slice(startIndex , endIndex)
    }));
  }

  function handleSelectReview (id) {
    dispatch(TOGGLE_SELECTED(id));
  }

  function handleUpdateReviewStatus () {
    const payload = buildStatusUpdates(selected, updateStatus);
    dispatch(update_review_status(payload, updateStatus))
    dispatch(RESET_SELECTION());
    setSelectAll(false);
  }

  function handleShowStatusUpdateModal (status) {
    dispatch(SET_STATE({
      key: 'updateStatus',
      value: status
    }));
    setStatusUpdateModal(true);
  }

  function handleConfirmStatusUpdate (confirmed) {
    confirmed && handleUpdateReviewStatus();
    setStatusUpdateModal(false);
  }

  function handleShowCommentModal (entry) {
    setCommentModal(true);
    dispatch(SET_STATE({
      key: 'editorComment',
      value: {
        ...editorComment,
        id: entry.id,
        review: entry.review,
        comment: entry.comment
      }
    }));
  }

  function handleSaveComment () {
    const payload = [
      {
        id: editorComment.id,
        editorComment: editorComment.comment
      }
    ];
    dispatch(update_review_comment(payload))
    setCommentModal(false);
  }

  function handleUpdateComment (ev) {
    dispatch(SET_STATE({
      key: 'editorComment',
      value: {
        ...editorComment,
        comment: ev.target.value
      }
    }));
  }

  function handleAddFilter () {
    setFilterModal(true);
  }

  function handleChangeFilterDate (date, type = 'dateFrom') {
    dispatch(SET_STATE({
      key: 'filter',
      value: {
        ...filter,
        [type]: formatFilterDate(date)
      }
    }));
  }

  function handleChangeFilterStatus (ev) {
    dispatch(SET_STATE({
      key: 'filter',
      value: {
        ...filter,
        status: ev.target.value
      }
    }));
  }

  function handleApplyFilter () {
    const dateFrom = new Date(filter.dateFrom).getTime();
    const dateTo = new Date(filter.dateTo).getTime();
    const filterSelection = reviews.filter((review) => {
      const reviewDate = new Date(review.dateAdded).getTime();
      return (
        filter.status === 'ALL'
          ? reviewDate >= dateFrom && reviewDate <= dateTo
          : reviewDate >= dateFrom && reviewDate <= dateTo && review.status === filter.status
      );
    });
    handlePageChange(1, filterSelection);
    dispatch(SET_STATE({
      key: 'reviews',
      value: filterSelection
    }));
    dispatch(SET_STATE({
      key: 'filter',
      value: {
        ...filter,
        applied: true
      }
    }));
    setFilterModal(false);
  }

  function handleClearFilter () {
    dispatch(fetch_reviews(pageNumber, pageSize));
    dispatch(SET_STATE({
      key: 'filter',
      value: {
        ...filter,
        applied: false
      }
    }));
    if (selectAll) setSelectAll(false);
    dispatch(RESET_SELECTION());
  }

  function handleSelectAll () {
    setSelectAll(!selectAll);
    if (!selectAll) {
      dispatch(SET_STATE({
        key: 'selected',
        value: reviews.map((review) => review.id)
      }));
    } else {
      dispatch(RESET_SELECTION());
    }
  }

  function handleCloseModal (type = 'filter') {
    type === 'filter' 
      ? setFilterModal(false)
      : setCommentModal(false);
  }

  function handleCloseServerErrorModal () {
    dispatch(SET_STATE( {
      key: 'status',
      value: 'idle'
    }));
  }

  return (
    <>
      <div className='main'>
        <div className='header flex flex-between align-center'>
          <h2>Reviews</h2>
          <div className='toolbar'>
          <button
              className='filter-btn'
              onClick={
                filter.applied
                  ? () => handleClearFilter()
                  : () => handleAddFilter()
              }
              disabled={fetchStatus === 'loading'}
            >
              {filter.applied ? 'Clear filter' : 'Add filter'}
            </button>
            <button
              className='blue-btn'
              onClick={() => handleShowStatusUpdateModal('PUBLISHED')}
              disabled={selected.length === 0}
            >
              Publish
            </button>
            <button
              className='blue-btn'
              onClick={() => handleShowStatusUpdateModal('FLAGGED')}
              disabled={selected.length === 0}
            >
              Flag
            </button>
            <button
              className='blue-btn'
              onClick={() => handleShowStatusUpdateModal('UNPUBLISHED')}
              disabled={selected.length === 0}
            >
              Unpublish
            </button>
          </div>
        </div>
        <div className='reviews-header-wrapper'>
          <ReviewsHeader
            checked={selectAll}
            handleCheck={handleSelectAll}
          />
        </div>
        {fetchStatus === 'loading' && (
          <div className='loading'>
            <BaseLoadingSpinner />
          </div>
        )}
        {fetchStatus === 'error' && 
          <ServerErrorModal close={handleCloseServerErrorModal} />
        }
        {fetchStatus === 'success' && (
          <div className='reviews-entries'  cy="review-rows">
            {
              pagedReviews.map((entry) => (
                <ReviewsEntry
                  key={entry.id}
                  entry={entry}
                  selected={selected}
                  handleSelectReview={handleSelectReview}
                  handleShowCommentModal={handleShowCommentModal}
                />
              ))
            }
            <BasePaginationNumbers
                      hits={totalReviewsCount}
                      pageNumber={pageNumber}
                      pageSize = {pageSize}
                      handlePageChange={handlePageChange}
                    />
            
          </div>
          )}
        {fetchStatus === 'idle' && (
          <div className='no-reviews'>
            <h3>No reviews to display.</h3>
          </div>
        )}
      </div>
      {commentModal && (
        <ReviewsModal
          buttonTxt='Save'
          handleBtnClick={(ev) => handleSaveComment('save', ev)}
          handleClose={() => handleCloseModal('comment')}  
        >
          <ReviewsComment
            editorComment={editorComment}
            onChange={handleUpdateComment}
          />
        </ReviewsModal>
      )}
      {filterModal && (
        <ReviewsModal
          buttonTxt='Apply Filter'
          handleBtnClick={handleApplyFilter}
          handleClose={() => handleCloseModal()}  
        >
          <ReviewsFilter
            filter={filter}
            onChangeDate={handleChangeFilterDate}
            onChangeSelect={handleChangeFilterStatus}
          />
        </ReviewsModal>
      )}
      {statusUpdateModal && (
        <BaseModal>
          <div className='modal'>
            <div className='modal-body'>
              <div className='modal-body-msg'>Are you sure you want to update {selected.length} review{selected.length > 1 ? 's' : ''} to have a status of {updateStatus} ?</div>
            </div>
            <div className='modal-footer'>
              <button
                className='blue-btn'
                onClick={() => handleConfirmStatusUpdate(false)}
              >
                Cancel
              </button>
              <button
                className='blue-btn'
                onClick={() => handleConfirmStatusUpdate(true)}
              >
                Continue
              </button>
            </div>
          </div>
        </BaseModal>
      )}
    </>
  );
};

export default Reviews;