import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { getJwtToken } from '../utils';
import { CHAPTERS_URL } from '../../utils/Constants';

/* How to inject the authorization header once for all request? */
axios.interceptors.request.use(
  async function (config) {
    const jwtToken = await getJwtToken();
    config.headers = { Authorization: jwtToken };
    config.params = { filterFields: false };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const initialState = {
  data: [],
  status: 'idle'
};

export const chaptersSlice = createSlice({
  name: 'chapters',
  initialState,
  reducers: {
    SET_CHAPTERS (state, { payload }) {
      state.data = payload;
    },
    UPDATE_CHAPTER (state, { payload: { value, field, id } }) {
      const index = state.data.findIndex((o) => o.id === id);
      if (index === -1) {
        state.data.push({ [field]: value, id });
      } else {
        state.data[index][field] = value;
      }
    },
    HANDLE_STATUS (state, { payload }) {
      state.status = payload;
    },
    ADD_CHAPTER (state) {
      // find the highest id value
      const lastId = state.data.reduce((acc, curr) => (+acc > +curr.id ? +acc : +curr.id), 0);
      state.data.push({ id: lastId + 1, title: '', url: '' });
    },
    DELETE_LAST_CHAPTER (state) {
      state.data = state.data.slice(0, -1);
    }
  }
});

export const { SET_CHAPTERS, ADD_CHAPTER, DELETE_LAST_CHAPTER, UPDATE_CHAPTER, HANDLE_STATUS } =
  chaptersSlice.actions;

export const fetch_chapters = ({ id, language }) =>
  async function (dispatch) {
    try {
      dispatch(HANDLE_STATUS('fetching'));
      const url = CHAPTERS_URL.replace('{courseId}', id).replace('{courseLanguage}', language);
      const { data } = await axios.get(url);
      dispatch(SET_CHAPTERS(data));
      dispatch(HANDLE_STATUS('idle'));
    } catch (e) {
      dispatch(HANDLE_STATUS('error'));
    }
  };

export const save_chapters = ({ id, language }) =>
  async function (dispatch, getState) {
    try {
      dispatch(HANDLE_STATUS('saving'));
      const {
        chapters: { data }
      } = getState();
      const url = CHAPTERS_URL.replace('{courseId}', id).replace('{courseLanguage}', language);
      await axios.put(url, data);
      dispatch(HANDLE_STATUS('success'));
      setTimeout(() => {
        dispatch(HANDLE_STATUS('idle'));
      }, 3000);
    } catch (e) {
      dispatch(HANDLE_STATUS('error'));
    }
  };

export default chaptersSlice.reducer;
