import { Auth } from 'aws-amplify';
import axios from 'axios';
import {
  API_GATEWAY_URL,
  CHAPTERS_URL,
  CONTENT_METADATA_URL,
  COURSES_METADATA_URL,
  DOI_URL,
  LIVE_COURSES_ID_URL,
  METADATA_URL,
  MODULE_MAPPING_URL,
  PRE_TEST_QUESTIONS_URL,
  SAVE_CONTENT_METADATA_URL,
  SEARCH_API_URL
} from '../utils/Constants';

export const fetchDoi = async function ({ id, language }) {
  const jwtToken = await getJwtToken();
  const url = DOI_URL.replace('{courseId}', id).replace('{courseLanguage}', language);
  const options = { headers: { Authorization: jwtToken } };
  const { data } = await axios(url, options);
  return data;
};

export const updateDoi = async function ({ id, language, externalId }) {
  const jwtToken = await getJwtToken();
  const options = { headers: { Authorization: jwtToken } };
  const body = { externalId, moduleId: id, locale: language };
  await axios.put(MODULE_MAPPING_URL, body, options);
};

export const fetchChapters = async function ({ id, language }) {
  const jwtToken = await getJwtToken();
  const url = CHAPTERS_URL.replace('{courseId}', id).replace('{courseLanguage}', language);
  const options = { headers: { Authorization: jwtToken } };
  const { data } = await axios.get(url, options);
  return data;
};

export const updateChapters = async function ({ id, language, chapters }) {
  const jwtToken = await getJwtToken();
  const url = CHAPTERS_URL.replace('{courseId}', id).replace('{courseLanguage}', language);
  const options = { headers: { Authorization: jwtToken } };
  await axios.put(url, chapters, options);
};

export const fetchQuestions = async function ({ id, language }) {
  const jwtToken = await getJwtToken();
  const url = PRE_TEST_QUESTIONS_URL.replace('{courseId}', id).replace(
    '{courseLanguage}',
    language
  );
  const options = {
    headers: { Authorization: jwtToken },
    params: { filterFields: false }
  };
  const { data } = await axios.get(url, options);
  return data;
};

export const updateQuestions = async function ({ id, language, questions }) {
  const jwtToken = await getJwtToken();
  const url = PRE_TEST_QUESTIONS_URL.replace('{courseId}', id).replace(
    '{courseLanguage}',
    language
  );
  const options = { headers: { Authorization: jwtToken } };
  await axios.put(url, questions, options);
};

export const fetchCollection = async function ({ id, language }) {
  const jwtToken = await getJwtToken();
  const url = CONTENT_METADATA_URL.replace('{id}', id).replace('{language}', language);
  const options = { headers: { Authorization: jwtToken } };
  const { data } = await axios.get(url, options);
  return data;
};

export const fetchSearch = async function (accessResources) {
  const jwtToken = await getJwtToken();
  const url = SEARCH_API_URL +  accessResources;
  const options = { headers: { Authorization: jwtToken } };
  const { data } = await axios.get(url, options);
  return data.results;
};

export const updateCollectionImg = async function (formData) {
  const jwtToken = await getJwtToken();
  const options = { headers: { Authorization: jwtToken } };
  const url = `${API_GATEWAY_URL}/image-upload?locale=en-gb`;
  return await axios.post(url, formData, options);
};

export const updateMetadata = async function (data) {
  const jwtToken = await getJwtToken();
  const options = { headers: { Authorization: jwtToken } };
  return await axios.put(SAVE_CONTENT_METADATA_URL, data, options);
};

export const updateCourseMetadata = async function (data) {
  const jwtToken = await getJwtToken();
  const options = { headers: { Authorization: jwtToken } };
  return await axios.put(`${SAVE_CONTENT_METADATA_URL}/course`, data, options);
};

export const createCourseMetadata = async function (data) {
  const jwtToken = await getJwtToken();
  const options = { headers: { Authorization: jwtToken } };
  return await axios.post(`${SAVE_CONTENT_METADATA_URL}/course`, data, options);
};

export const createMetadata = async function (data) {
  const jwtToken = await getJwtToken();
  const options = { headers: { Authorization: jwtToken } };
  return await axios.post(SAVE_CONTENT_METADATA_URL, data, options);
};

export const fetchBatchMetadata = async function (type, locale) {
  const jwtToken = await getJwtToken();
  const options = { headers: { Authorization: jwtToken } };
  const url = `${METADATA_URL}?type=${type}${locale ? '&locale=' + locale : ''}`;
  const { data } = await axios.get(url, options);
  return data;
};

export const fetchCoursesMetadata = async function (courses, language = 'en-gb') {
  const jwtToken = await getJwtToken();
  const options = { headers: { Authorization: jwtToken } };
  const url = `${COURSES_METADATA_URL}?moduleIds=${courses}&language=${langSwitch(language)}`;
  const { data } = await axios.get(url, options);
  return data;
};

export const fetchLiveCoursesIds = async function (language = 'en-gb') {
  const url = LIVE_COURSES_ID_URL.replace('{locale}', langSwitch(language));
  const { data } = await axios(url);
  return data;
};

function getJwtToken () {
  return Auth.currentSession().then(({ idToken: { jwtToken } }) => jwtToken);
}

function langSwitch (lang) {
  switch (lang) {
    case 'en_GB':
      return 'en-gb';
    default:
      return 'en-gb';
  }
}
