import './css/loading-spinner.scss';

export const BaseLoadingSpinner = function () {
  return (
    <div className="lds-roller" cy="base-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
