import computer from '../assets/server-error-icon.svg';
import closeIcon from '../assets/close-x-icon.svg';
import './css/server-error-modal.scss';

export const ServerErrorModal = function ({ msg, close }) {
  return (
    <div className="server-error-modal text-center relative">
      <img
        className="close-icon absolute pointer"
        src={closeIcon}
        alt="close icon"
        onClick={close}
      />
      <img src={computer} alt="" />
      <p>
        Sorry, there was an error <br /> communicating with the server
        <br />
        <b>{msg}</b>
        <br />
        <span className="inline-block">
          Please contact the Learning tech team <br /> on Slack to fix it.
        </span>
      </p>
      <div className="actions">
        <a href="https://bmjgroup.slack.com/archives/CKUK1L3PX" target="_blank">
          <button>Go to Slack</button>
        </a>
        <span className="pointer" onClick={close}>
          Close
        </span>
      </div>
    </div>
  );
};
