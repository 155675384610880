import './css/base-chips-group.scss';
import { BaseChip } from './BaseChip';

export const BaseChipsGroup = function ({ name, label, options = [], selected = [], onSelect }) {
  function handleChipSelection ({ section = name, action = 'single', id }) {
    onSelect({ section, id, action, list: options });
  }

  return (
    <div className="base-chips-group m-r-24 m-t-24 block" name={name} cy={name}>
      <b className="m-b-8 block">{label || name}</b>
      <div>
        <span cy="chips-count">({selected.length} selected)</span>
        <button onClick={() => handleChipSelection({ action: 'selectAll' })} cy="select-all">
          Select all
        </button>
        <button onClick={() => handleChipSelection({ action: 'deselectAll' })} cy="deselect-all">
          Deselect all
        </button>
      </div>
      <div cy="tags">
        {options?.map(({ displayName, title, id }) => (
          <BaseChip
            label={displayName || title}
            key={displayName || title}
            id={id}
            selected={selected.map((o) => o.id).some((val) => val === id)}
            section={name}
            onClick={handleChipSelection}
          />
        ))}
      </div>
    </div>
  );
};
