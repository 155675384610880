import { MultiSelect } from 'primereact/multiselect';
import { useDispatch, useSelector } from 'react-redux';
import { CopyToClipboard, StatusSelector, BaseChip } from './index';
import {
  SET_COLLECTION_PROP,
  UPDATE_SUBSCRIPTIONS,
  UPDATE_ADDITIONAL_SUBSCRIPTION
} from '../store/modules/collection';
import './css/key-details-section.scss';

export const CollectionKeyDetails = function () {
  const {
    subscriptionsList,
    additionalSubscriptionsList,
    data: { id, status, title, locale, accessResources }
  } = useSelector((state) => state.collection);

  const additionalSubscriptionsGroup = [
    {
      label: 'Journal',
      items: additionalSubscriptionsList.slice(0, 8)
    },
    {
      label: 'India course',
      items: additionalSubscriptionsList.slice(8, 48)
    },
    {
      label: 'Customer',
      items: additionalSubscriptionsList.slice(48, 52)
    },
    {
      label: 'Collection/Topic',
      items: additionalSubscriptionsList.slice(52, 56)
    },
    {
      label: 'China',
      items: additionalSubscriptionsList.slice(56, 57)
    },
    {
      label: 'BMA',
      items: additionalSubscriptionsList.slice(57, 62)
    },
    {
      label: 'Research to Publication',
      items: additionalSubscriptionsList.slice(62, 63)
    }
  ];

  const dispatch = useDispatch();

  function handlePropUpdate({ key, value }) {
    dispatch(SET_COLLECTION_PROP({ key, value }));
  }

  function handleUpdateSubscriptions(arr) {
    dispatch(UPDATE_SUBSCRIPTIONS(arr));
  }

  function handleAdditional(arr) {
    dispatch(UPDATE_ADDITIONAL_SUBSCRIPTION(arr));
  }

  return (
    <section className="key-details-section" id="key-details">
      <header className="flex flex-between">
        <h2>Key details</h2>
        <div className="flex-center">
          <StatusSelector name="status" status={status} onChange={handlePropUpdate} />
        </div>
      </header>

      <div>
        <label htmlFor="title">
          Collection title
          <br />
          <textarea
            className="w100"
            cy="collection-title"
            id="title"
            value={title}
            onInput={(ev) => handlePropUpdate({ key: 'title', value: ev.target.value })}
            placeholder="course title"
          />
        </label>
        <span>Locale: {locale}</span>
      </div>

      <div className="unique-updated flex m-t-24">
        <div className="m-r-24">
          <label htmlFor="unique">
            Unique ID <br />
            <input
              type="text"
              cy="course-id"
              id="unique"
              value={id || ''}
              disabled
              onInput={() => {}}
            />
          </label>
          <CopyToClipboard />
        </div>
      </div>

      <div className="subscriptions-chips m-t-24" cy="subscriptions">
        <b>Subscriptions</b>
        <br />
        {subscriptionsList?.map(({ displayName, id }) => (
          <BaseChip
            label={displayName}
            id={id}
            key={id}
            selected={accessResources?.map((o) => o.id).some((val) => +val === +id)}
            section="keyDetails"
            onClick={handleUpdateSubscriptions}
          />
        ))}
      </div>

      <div id="bmj-journals-subscriptions" className="relative m-t-24">
        <label htmlFor="additional" cy="additional-subscriptions">
          Additional subscriptions <br />
          <MultiSelect
            value={accessResources?.filter(({ id }) => +id > 2)}
            options={additionalSubscriptionsGroup}
            onChange={(e) => handleAdditional(e.value)}
            optionLabel="displayName"
            optionGroupLabel="label"
            optionGroupChildren="items"
            placeholder="Search all other subscriptions"
            display="chip"
            filter
            resetFilterOnHide
          />
        </label>
      </div>
    </section>
  );
};
