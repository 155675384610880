import { BaseCheckbox, BaseTextarea } from './';
import './css/base-answer.scss';

export const BaseAnswer = function ({
  name,
  cy,
  index,
  value,
  answer,
  correct,
  onInput,
  onSelect
}) {
  return (
    <div className="base-answer flex" cy={cy || name}>
      <BaseTextarea
        name={`${name}${index}`}
        value={value.replace(/<[^>]+>/g, '')}
        onInput={onInput}
        label={`Answer ${index}`}
      />
      <BaseCheckbox
        name={`${name}${index}`}
        value={answer}
        correct={correct}
        onChange={onSelect}
        label="Correct"
      />
    </div>
  );
};
