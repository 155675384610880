import { createSlice } from '@reduxjs/toolkit';
import { fetchCollection, fetchSearch } from '../api';

export const subscriptionsList = [
  {
    displayName: 'BMJ Learning standard',
    id: '1',
    code: 'bmj-learning-standard'
  },
  {
    displayName: 'Free',
    id: '2',
    code: 'bmj-learning-free'
  }
];
export const additionalSubscriptionsList = [
  // Journal
  { displayName: 'BMJ Learning JMG', id: '31', code: 'bmj-learning-highwire-jmg' },
  { displayName: 'BMJ Learning Journal', id: '23', code: 'bmj-learning-journal' },
  { displayName: 'BMJ Learning Quality', id: '22', code: 'bmj-learning-quality' },
  {
    displayName: 'British Journal of Sports Medicine',
    id: '11',
    code: 'bmj-learning-highwire-bjsm'
  },
  { displayName: 'Education in Heart', id: '7', code: 'bmj-learning-highwire-education-in-heart' },
  { displayName: 'Gut', id: '8', code: 'bmj-learning-highwire-gut' },
  { displayName: 'Education in heart', id: '9', code: 'bmj-learning-highwire-heart' },
  { displayName: 'Journal of Clinical Pathology', id: '10', code: 'bmj-learning-highwire-jcp' },
  // India course
  { displayName: 'BMJ Learning CKD India 1', id: '60', code: 'bmj-learning-ckd-india-1' },
  { displayName: 'BMJ Learning CKD India 2', id: '61', code: 'bmj-learning-ckd-india-2' },
  { displayName: 'BMJ Learning CKD India 3', id: '62', code: 'bmj-learning-ckd-india-3' },
  { displayName: 'BMJ Learning CKD India 4', id: '63', code: 'bmj-learning-ckd-india-4' },
  { displayName: 'BMJ Learning CKD India 5', id: '64', code: 'bmj-learning-ckd-india-5' },
  { displayName: 'BMJ Learning CKD India 6', id: '65', code: 'bmj-learning-ckd-india-6' },
  { displayName: 'BMJ Learning CKD India 7', id: '66', code: 'bmj-learning-ckd-india-7' },
  { displayName: 'BMJ Learning CKD India 8', id: '67', code: 'bmj-learning-ckd-india-8' },
  { displayName: 'BMJ Learning CKD India 9', id: '68', code: 'bmj-learning-ckd-india-9' },
  { displayName: 'BMJ Learning CKD India 10', id: '69', code: 'bmj-learning-ckd-india-10' },
  { displayName: 'BMJ Learning CKD India 11', id: '70', code: 'bmj-learning-ckd-india-11' },
  { displayName: 'BMJ Learning CKD India 12', id: '71', code: 'bmj-learning-ckd-india-12' },
  { displayName: 'BMJ Learning CKD India 13', id: '72', code: 'bmj-learning-ckd-india-13' },
  { displayName: 'BMJ Learning diabetes 1', id: '41', code: 'bmj-india-diabetes-unit-1' },
  { displayName: 'BMJ Learning diabetes 2', id: '42', code: 'bmj-india-diabetes-unit-2' },
  { displayName: 'BMJ Learning diabetes 3', id: '43', code: 'bmj-india-diabetes-unit-3' },
  { displayName: 'BMJ Learning diabetes 4', id: '44', code: 'bmj-india-diabetes-unit-4' },
  { displayName: 'BMJ Learning diabetes 5', id: '45', code: 'bmj-india-diabetes-unit-5' },
  { displayName: 'BMJ Learning diabetes 6', id: '46', code: 'bmj-india-diabetes-unit-6' },
  { displayName: 'BMJ Learning India diabetes 7', id: '89', code: 'bmj-india-diabetes-unit-7' },
  {
    displayName: 'BMJ Learning India Palliative Care 1',
    id: '75',
    code: 'bmj-learning-palliative-india-1'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 2',
    id: '76',
    code: 'bmj-learning-palliative-india-2'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 3',
    id: '77',
    code: 'bmj-learning-palliative-india-3'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 4',
    id: '78',
    code: 'bmj-learning-palliative-india-4'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 5',
    id: '79',
    code: 'bmj-learning-palliative-india-5'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 6',
    id: '80',
    code: 'bmj-learning-palliative-india-6'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 7',
    id: '81',
    code: 'bmj-learning-palliative-india-7'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 8',
    id: '82',
    code: 'bmj-learning-palliative-india-8'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 9',
    id: '83',
    code: 'bmj-learning-palliative-india-9'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 10',
    id: '84',
    code: 'bmj-learning-palliative-india-10'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 11',
    id: '85',
    code: 'bmj-learning-palliative-india-11'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 12',
    id: '86',
    code: 'bmj-learning-palliative-india-12'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 13',
    id: '87',
    code: 'bmj-learning-palliative-india-13'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 14',
    id: '88',
    code: 'bmj-learning-palliative-india-14'
  },
  {
    displayName: 'BMJ Learning: Rheumatology Unit 1',
    id: '99',
    code: 'bmj-learning-rheum-india-1'
  },
  {
    displayName: 'BMJ Learning: Rheumatology Unit 2',
    id: '100',
    code: 'bmj-learning-rheum-india-2'
  },
  {
    displayName: 'BMJ Learning: Rheumatology Unit 3',
    id: '101',
    code: 'bmj-learning-rheum-india-3'
  },
  {
    displayName: 'BMJ Learning: Rheumatology Unit 4',
    id: '102',
    code: 'bmj-learning-rheum-india-4'
  },
  {
    displayName: 'BMJ Learning: Rheumatology Unit 5',
    id: '103',
    code: 'bmj-learning-rheum-india-5'
  },
  {
    displayName: 'BMJ Learning Rheumatology Unit 6',
    id: '104',
    code: 'bmj-learning-rheum-india-6'
  },
  // Customer
  { displayName: 'BMJ Learning: ADB', id: '95', code: 'bp-learning-adb' },
  { displayName: 'BMJ Learning Darwin', id: '56', code: 'bmj-learning-dtra' },
  { displayName: 'BMJ Learning Elucidat Test', id: '57', code: 'bmj-learning-elucidat-test' },
  { displayName: 'BMJ Learning - RSM study day modules', id: '2', code: 'bmj-learning-rsm' },
  // Collection/topic
  {
    displayName: 'BMJ Learning Basic Life Support',
    id: '25',
    code: 'bmj-learning-basic-life-support'
  },
  {
    displayName: 'Learning: Common respiratory conditions',
    id: '94',
    code: 'learning-common-respiratory-conditions'
  },
  { displayName: 'BMJ Learning ECG Skills', id: '29', code: 'bmj-learning-ecg-skills' },
  { displayName: 'BMJ Learning Surgical Skills', id: '17', code: 'bmj-learning-surgical-skills' },
  // China
  { displayName: 'BMJL Chinese modules', id: '38', code: 'bmj-learning-chinese-modules' },
  // BMA
  { displayName: 'BMA Membership', id: '3', code: 'bmj-learning-bma-membership' },
  { displayName: 'BMA reps', id: '4', code: 'bmj-learning-bma-reps' },
  { displayName: 'BMA Staff', id: '27', code: 'bmj-learning-bma-staff' },
  { displayName: 'BMJ Learning: BMA PCN', id: '93', code: 'bmj-learning-bma-pcn' },
  { displayName: 'Imperial Research Institution', id: '98', code: 'imperial-research-institution' },
  // Rtop
  { displayName: 'Research to Publication', id: '36', code: 'bmj-learning-r2p' }
];
const emptyCourse = () => ({
  accessResources: []
});

const initialState = {
  modules: [],
  status: 'idle',
  additionalSubscriptionsList,
  subscriptionsList,
  data: emptyCourse()
};

export const accreditationsSlice = createSlice({
  name: 'accreditations',
  initialState,
  reducers: {
    SET_STATE (state, { payload: { key, value } }) {
      state[key] = value;
    },
    UPDATE_ADDITIONAL_SUBSCRIPTION(state, { payload = [] }) {
      state.data.accessResources = payload;
    }
  }
});

export const {
  SET_STATE,
  UPDATE_ADDITIONAL_SUBSCRIPTION
} = accreditationsSlice.actions;

export const fetch_search_api = (param) =>
  async function (dispatch) {
    dispatch(SET_STATE({
      key: 'status',
      value: 'loading'
    }));
    try {
      await fetchSearch(param)
      .then((data) => data.filter((course) => course.status === 'LIVE'))
      .then((moduleList) => dispatch(SET_STATE({
        key: 'modules',
        value: moduleList
      })));
      dispatch(SET_STATE({
        key: 'status',
        value: 'success'
      }));
    } catch(err) {
      console.log('err:', err);
      dispatch(SET_STATE({
        key: 'modules',
        value: []
      }))
      dispatch(SET_STATE({
        key: 'status',
        value: 'error'
      }));
    }
};

export const fetch_accreditations = (param) =>
  async function (dispatch) {
    dispatch(SET_STATE({
      key: 'status',
      value: 'loading'
    }));
    try {
      await fetchCollection(param)
      .then((data) => [data].filter((course) => course.status === 'LIVE'))
      .then((moduleList) => dispatch(SET_STATE({
        key: 'modules',
        value: moduleList
      })));
      dispatch(SET_STATE({
        key: 'status',
        value: 'success'
      }));
    } catch(err) {
      console.log('err:', err);
      dispatch(SET_STATE({
        key: 'modules',
        value: []
      }))
      dispatch(SET_STATE({
        key: 'status',
        value: 'error'
      }));
    }
};

export default accreditationsSlice.reducer;