import Quill from 'quill';
import * as QuillTableUI from 'quill-table-ui';
import 'quill/dist/quill.snow.css';
import { useMemo } from 'react';
import ReactQuill from 'react-quill';

Quill.register('modules/table', QuillTableUI.default);
Quill.register('modules/tableUI', QuillTableUI.default);

export const RichTextEditor = function ({
  title,
  name,
  cy,
  questionId,
  optionId,
  optional,
  value = '',
  large,
  small,
  onChange
}) {
  let size = '';
  if (small) {
    size += ' small-size-editor';
  }
  if (large) {
    size += ' large-size-editor';
  }
  const options = useMemo(
    () => ({
      placeholder: 'add text here...',
      theme: 'snow',
      modules: {
        toolbar: [
          'bold',
          'italic',
          'underline',
          'strike',
          { script: 'super' },
          { script: 'sub' },
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { color: [] },
          { background: [] },
          { header: [1, 2, 3, false] },
          { list: 'ordered' },
          { list: 'bullet' },
          'link'
        ]
      }
    }),
    []
  );

  function handleOnChange (data) {
    const payload = {
      id: questionId,
      value: data
    };
    optionId ? (payload.optionId = optionId) : (payload.fieldName = name);
    onChange(payload);
  }

  return (
    <div className="rich-text-editor" cy={cy || name}>
      <p className="editor-title">
        <b>{title}</b>
        {optional && <i className="f-14">&nbsp;&nbsp;(optional)</i>}
      </p>
      <ReactQuill
        theme={options.theme}
        className={size}
        modules={options.modules}
        placeholder={options.placeholder}
        value={value}
        onChange={handleOnChange}
      />
    </div>
  );
};
