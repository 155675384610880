import deleteBtn from '../assets/delete.svg';

export const BaseDeleteButton = function ({ text = '', onClick }) {
  return (
    <button
      onClick={onClick}
      cy={text.replace(/ /g, '-').toLowerCase()}
      className="base-action-btns flex align-center no-border bg-transparent"
    >
      <img className="plus-icon pointer m-r-8" src={deleteBtn} alt="plus icon" />
      {text}
    </button>
  );
};
