import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  Grid,
  Button
} from '@material-ui/core';
import { ORGANISATIONS_URL } from '../../utils/Constants';
import './organisation.scss';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    width: '100%',
    margin: '0 auto'
  }
});

const OrganisationList = () => {
  const [organisations, setOrganisations] = useState([]);

  useEffect(() => {
    fetch(ORGANISATIONS_URL)
      .then((response) => response.json())
      .then((orgList) => setOrganisations(orgList))
      .catch((err) => console.error(err));
  }, []);

  const classes = useStyles();

  return (
    <main className="organisation-list">
      <Grid className="org-list">
        <Grid container>
          <Typography variant="h2" component="h2">
            Organisation Details
          </Typography>
        </Grid>
        <Grid>
          <TableContainer component={Paper} style={{ maxHeight: 500 }}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Organisation id</TableCell>
                  <TableCell align="left">Organisation name</TableCell>
                  <TableCell align="left">Organisation statement</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="left">View details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {organisations.map((organisation) => (
                  <TableRow key={organisation.id}>
                    <TableCell component="th" scope="row">
                      {organisation.id}
                    </TableCell>
                    <TableCell align="left">{organisation.name}</TableCell>
                    <TableCell align="left">{organisation.organisationStatement}</TableCell>
                    <TableCell align="left">
                      {organisation.isActive ? 'ACTIVE' : 'INACTIVE'}
                    </TableCell>

                    <TableCell align="left">
                      <Link
                        to={{
                          pathname: '/organisation/' + organisation.id,
                          state: { organisation: organisation }
                        }}
                      >
                        View
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid>
          <Link to="/new-organisation">
            <Button variant="contained" className="primary-button">
              Add Organisation
            </Button>
          </Link>
        </Grid>
      </Grid>
    </main>
  );
};

export default OrganisationList;
