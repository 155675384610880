import { atom, useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { fetchBatchMetadata } from '../api';

// used for the second useEffect - the one with return callback fn
// which runs only when page exits
let clonedState = {
  data: [],
  course: [],
  collection: []
};
function updateClonedState (data) {
  clonedState = data;
}

export const homeState = atom({
  data: [],
  course: [],
  collection: []
});

export const useBatchMetadata = function (locale = 'en-gb') {
  const [state, setData] = useAtom(homeState);
  const [status, setStatus] = useState('idle');
  const [type, setType] = useState('course');
  const [search, setSearch] = useState('');

  useEffect(() => {
    // if courses or collections hasn't been fetched, then show the spinner
    if (!state[type]?.length) {
      setStatus('loading');
    } else {
      return;
    }
    const response = fetchBatchMetadata(type, locale);
    response
      .then((data) => {
        const payload = {
          ...state,
          data,
          [type]: data
        };
        setData(payload);
        // we clone the data to use it inside useEffect callback bellow
        updateClonedState(payload);
        setStatus('success');
      })
      .catch((e) => {
        // todo connect modal to show error
        console.log(e.response ? e.response.data : e);
      });
  }, [type]);

  // on cleanup, we set the data to be the course content
  // because when landing on the homepage,
  // the courses are at first listed in the table
  // we use here the clonedState data
  useEffect(() => {
    // here, this use effect does not have access to atom state,
    // because the dependency array is empty, to make sure it only runs once, on page exit
    return () => {
      const payload = {
        ...clonedState,
        data: clonedState.course
      };
      setData(payload);
    };
  }, []);

  function handleType (type) {
    const payload = {
      ...state,
      data: state[type]
    };
    setData(payload);
    setType(type);
    setSearch('');
  }

  function handleSearch (value) {
    setSearch(value);
  }

  return {
    status,
    type,
    search,
    handleSearch,
    handleType
  };
};
