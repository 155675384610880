import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { fetchBatchMetadata } from '../../store/api';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const ModuleList = () => {
  const [modules, setModules] = useState([]);

  useEffect(() => {
    fetchBatchMetadata('course', 'en-gb').then((moduleList) => setModules(moduleList));
  }, []);

  const classes = useStyles();

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Course id</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Title</TableCell>
              <TableCell align="left">View</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {modules.map((module) => (
              <TableRow key={module.id}>
                <TableCell component="th" scope="row">
                  {module.id}
                </TableCell>
                <TableCell align="left">{module.status}</TableCell>
                <TableCell align="left">{module.title}</TableCell>
                <TableCell align="left">
                  <Link to={'/course/' + module.id}>View</Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ModuleList;
