import downTriangle from '../assets/down-triangle.svg';
import './css/status-selector.scss';

export const StatusSelector = function ({ title, status, onChange }) {
  const statuses = ['ARCHIVE', 'LIVE', 'PENDING'];
  const statusList = status === 'ARCH' ? statuses.reverse() : statuses;

  function trimStatusName (string) {
    switch (string) {
      case 'ARCHIVE':
        return 'ARCH';
      case 'PENDING':
        return 'PEND';
      case 'LIVE':
        return 'LIVE';
    }
  }

  return (
    <div className="status-selector base-selector" cy="status-selector">
      <label htmlFor="status-select">
        {title && <span>Status: &nbsp;&nbsp;&nbsp;</span>}
        <select
          id="status-select"
          className="pointer capitalize"
          value={status}
          onChange={(ev) => {
            onChange({ key: 'status', value: ev.target.value });
          }}
        >
          {statusList.map((val) => (
            <option value={trimStatusName(val)} key={val}>
              {val.toLowerCase()}
            </option>
          ))}
        </select>
      </label>
      <img src={downTriangle} alt="" />
      <span id="status-indicator" className={status?.toLowerCase()} />
    </div>
  );
};
