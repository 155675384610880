import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_COURSE, HANDLE_CHIP_SELECTION } from '../store/modules/course';
import { audience, specialties as specialtiesList } from '../store/modules/collection';
import { BaseInput, BaseTabsMenu, BaseTextarea, BaseChipsGroup, BaseImageUpload } from './index';
import { updateCollectionImg } from '../store/api';
import './css/course-information.scss';

export const CourseInformation = function () {
  const dispatch = useDispatch();
  const {
    elucidatId,
    doi,
    moduleType,
    facultyDisclosure,
    category,
    duration,
    description,
    targetAudiences,
    homepageImage,
    partnersLogo
  } = useSelector((state) => state.course.data);
  const durationOptions = ['10 min', '30 min', '1 hour', '1 hour 30 min', 'Other'];
  const [durationString, setDurationString] = useState('');

  // we convert duration object to a string to match component value prop type
  useEffect(() => {
    let d = Object.keys(duration).reduce((acc, key, index) => {
      acc += `${duration[key]} ${key.replace('minutes', 'min').replace('hours', 'hour')}`;
      return index < Object.keys(duration).length - 1 ? acc + ' ' : acc;
    }, '');
    if (!durationOptions.includes(d)) {
      d = 'Other';
    }
    setDurationString(d);
  }, [duration]);

  function handleDuration({ value }) {
    if (value === 'Other') {
      setDurationString('Other');
      dispatch(UPDATE_COURSE({ key: 'duration', value: {} }));
      return;
    }
    // we make array out of string in the form of ['minutes', '30', 'hours', '1']
    const arr = value.replace('min', 'minutes').replace('hour', 'hours').split(' ').reverse();
    // we make object out of the array in the form of {minutes: '30', hours: '1'}
    const obj = arr.reduce((acc, curr, index) => {
      index % 2 === 0 && (acc[curr] = arr[index + 1]);
      return acc;
    }, {});
    // we need to reverse object order because is in the form of {minutes: x, hours: y} and the string is 1 hour 30 min
    const durationObj = Object.keys(obj)
      .reverse()
      .reduce((acc, key) => {
        acc[key] = obj[key];
        return acc;
      }, {});
    dispatch(UPDATE_COURSE({ key: 'duration', value: durationObj }));
  }

  function handleInput({ key, value }) {
    dispatch(UPDATE_COURSE({ key, value }));
  }

  function handleOtherDuration({ key, value }) {
    const obj = { ...duration };
    obj[key] = value;
    dispatch(UPDATE_COURSE({ key: 'duration', value: obj }));
  }

  function handleChipSelections(obj) {
    dispatch(HANDLE_CHIP_SELECTION(obj));
  }
  async function handleImage({ key, action, imageName, formData }) {
    switch (action) {
      case 'upload':
        dispatch(UPDATE_COURSE({key, value:key === 'partnersLogo' ? [imageName] : imageName}));
        await updateCollectionImg(formData);
        break;
      case 'delete':
        dispatch(UPDATE_COURSE({key, value:key === 'partnersLogo' ? null : ''}));
        break;
    }
  }

  return (
    <section className="course-information" id="course-information">
      <h2 className="m-b-24">Course information</h2>

      <BaseInput
        name={moduleType === 'MCQ' || doi ? 'doi' : 'elucidatId'}
        label={moduleType === 'MCQ' || doi ? 'DOI' : 'Elucidat id'}
        value={doi || elucidatId}
        classes="doi-field"
        onInput={handleInput}
      />

      <BaseTabsMenu
        name="duration"
        label="Duration"
        value={durationString}
        options={durationOptions}
        onSelect={handleDuration}
      />
      <div
        className={`other-duration ${
          durationString === 'Other' ? 'show-other-duration flex' : 'show-other-duration flex'
        }`}>
        <BaseInput
          name="hours"
          label="Hours"
          type="text"
          value={duration.hours}
          onInput={handleOtherDuration}
        />

        <BaseInput
          name="minutes"
          label="Minutes"
          type="text"
          value={duration.minutes}
          onInput={handleOtherDuration}
        />
      </div>

      <BaseTextarea
        name="description"
        label="Description"
        value={description}
        onInput={handleInput}
      />

      <BaseImageUpload
        name="homepageImage"
        label="Homepage image"
        imageName={homepageImage}
        handleImage={handleImage}
      />

      <BaseTextarea
        name="facultyDisclosure"
        label="Homepage alert"
        value={facultyDisclosure}
        onInput={handleInput}
      />

      <BaseChipsGroup
        name="targetAudiences"
        label="Target audiences"
        options={audience}
        selected={targetAudiences}
        onSelect={handleChipSelections}
      />

      <BaseChipsGroup
        name="category"
        label="Specialties"
        options={specialtiesList}
        selected={category}
        onSelect={handleChipSelections}
      />

      <BaseImageUpload
        name="partnersLogo"
        label="Partner logo"
        imageName={partnersLogo && partnersLogo[0]}
        handleImage={handleImage}
      />
    </section>
  );
};
