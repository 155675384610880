import { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import { SET_STATE } from '../store/modules/reviews';

export const ReviewsFilter = function ({
  filter = {},
  onChangeDate,
  onChangeSelect
}) {
  const dispatch = useDispatch();

  useEffect (() => {
    dispatch(SET_STATE({
      key: 'filter',
      value: {
        ...filter,
        status: 'ALL'
      }
    }));
  }, []);

  return (
    <div className='modal-body-filter'>
      <div className='filter-selector'>
        <label htmlFor='dateFrom'>Date From</label>
        <DatePicker
          id='dateFrom'
          selected={new Date(filter?.dateFrom)}
          dateFormat='d MMM, yyyy'
          onChange={(date) => onChangeDate(date)}
        />
      </div>
      <div className='filter-selector'>
        <label htmlFor='dateTo'>Date To</label>
        <DatePicker
          id='dateTo'
          selected={new Date(filter.dateTo)}
          dateFormat='d MMM, yyyy'
          onChange={(date) => onChangeDate(date, 'dateTo')}
        />
      </div>
      <div className='filter-selector'>
        <label htmlFor='status'>Status</label>
        <div className='select-status'>               
          <select
            name='status'
            id='status'
            onChange={(ev) => onChangeSelect(ev)}
            value={filter.status}
          >
            <option value='ALL'>ALL</option>
            <option value='UNPUBLISHED'>UNPUBLISHED</option>
            <option value='FLAGGED'>FLAGGED</option>
            <option value='PUBLISHED'>PUBLISHED</option>
            <option value='DELETED'>DELETED</option>
          </select>
        </div>
      </div>
    </div>
  );
};