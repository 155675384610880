import plus from '../assets/plus.svg';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_COURSE } from '../store/modules/course';
import './css/course-learning-objectives.scss';

export const CourseLearningObjectives = function ({ id, list }) {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.course.data);
  let objectives = list || data.objectives;

  function removeObjective(index) {
    const data = objectives.slice();
    data.splice(index, 1);
    dispatch(UPDATE_COURSE({ key: 'objectives', value: data }));
  }
  function addNewObjective() {
    dispatch(UPDATE_COURSE({ key: 'objectives', value: [...objectives, ''] }));
  }
  function updateOjective(index, value) {
    const data = objectives.slice();
    data[index] = value;
    dispatch(UPDATE_COURSE({ key: 'objectives', value: data }));
  }

  return (
    <section id={id} className="course-learning-objectives" cy="course-learning-objectives">
      <div className="content-wrapper">
        <h2>Learning objectives</h2>
        <p>After completing this course, you should know how to:</p>

        {objectives?.map((objective, index) => (
          <div className="course-objective" cy="objective" key={index}>
            <div>
              <span>Objective {index + 1}</span>
              {objectives.length > 1 && (
                <button onClick={() => removeObjective(index)} cy="remove-last-objective">
                  Delete
                </button>
              )}
            </div>
            <textarea
              placeholder={objective}
              className="w100"
              value={objective}
              onChange={(ev) => updateOjective(index, ev.target.value)}
            />
          </div>
        ))}

        <div className="flex flex-between">
          <button onClick={addNewObjective} cy="add-objective">
            <img className="plus-icon pointer" src={plus} alt="plus icon" />
            Add new objective
          </button>
        </div>
      </div>
    </section>
  );
};
