export const ReviewsComment = function ({
  editorComment = {},
  onChange
}) {

  return (
    <div className='modal-body-comments'>
      <h3>Review</h3>
      <p>{editorComment.review}</p>
      <h3>Editor Comments</h3>
      <textarea
        className='editor-comments-box'
        value={editorComment.comment}
        onChange={onChange}
      />
    </div>
  );
};