import { NavLink } from 'react-router-dom';
import logo from '../assets/BMJLearning-CMS-Logo.svg';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import React from 'react';
import './css/main-top-nav.scss';

export const MainTopNav = function () {
  return (
    <nav className="top-nav">
      <div>
        <NavLink to="/">
          <img src={logo} alt="logo" className="brand-logo" />
        </NavLink>
        <NavLink to="/">Manage modules</NavLink>
        <NavLink to="/organisations">Accredit by organisation</NavLink>
        <NavLink to="/batch-accreditation">Batch accredit</NavLink>
        <NavLink to="/reviews">Reviews</NavLink>

        <AmplifySignOut />
      </div>
    </nav>
  );
};
