import { configureStore } from '@reduxjs/toolkit';
import questions from '../store/modules/questions';
import chapters from '../store/modules/chapters';
import course from '../store/modules/course';
import collection from '../store/modules/collection';
import reviews from '../store/modules/reviews';
import accreditations  from '../store/modules/batch-accreditation';

export const store = configureStore({
  reducer: {
    questions,
    chapters,
    course,
    collection,
    reviews,
    accreditations
  }
});
