import downTriangle from '../assets/down-triangle.svg';
import './css/base-selector.scss';

export const BaseSelector = function ({ value = '', name, cy, label = '', list, handleUpdate }) {
  return (
    <div className="base-selector" cy={cy || name}>
      <span>{label} &nbsp;&nbsp;&nbsp;&nbsp;</span>
      <select
        id="status"
        value={value}
        onChange={(ev) => {
          handleUpdate({ key: name, value: ev.target.value });
        }}
      >
        {!value && (
          <option value="" disabled hidden>
            Select chapter
          </option>
        )}
        {list.map((val, index) => (
          <option value={val} key={index}>
            {val}
          </option>
        ))}
      </select>
      <img src={downTriangle} alt="icon" />
    </div>
  );
};
