import plus from '../assets/plus.svg';

export const BaseAddNewButton = function ({ name, disabled, cy, text = '', onClick }) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      cy={cy || name}
      className="base-action-btns flex align-center no-border bg-transparent"
    >
      <img className="plus-icon pointer m-r-8" src={plus} alt="plus icon" />
      {text}
    </button>
  );
};
