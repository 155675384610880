import { useState } from 'react';
import copyIcon from '../assets/copy.svg';

export const CopyToClipboard = function () {
  const [copyIdTxt, setCopyIdTxt] = useState('Copy');

  function copyIdToClipboard () {
    const input = document.getElementById('unique');
    input.select();
    navigator.clipboard.writeText(input.value);
    setCopyIdTxt('Copied');
    setTimeout(() => {
      setCopyIdTxt('Copy');
    }, 3000);
  }

  return (
    <button className="flex-center copy-id-button" cy="copy-course" onClick={copyIdToClipboard}>
      <img src={copyIcon} alt="" />
      {copyIdTxt}
      <i className={copyIdTxt === 'Copied' ? 'id-copied' : ''}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.79 3.78959L5.5 11.0896L2.71 8.28959C2.31788 7.89746 1.68212 7.89746 1.29 8.28959C0.89788 8.68171 0.89788 9.31746 1.29 9.70959L4.79 13.2096C5.18004 13.5973 5.80996 13.5973 6.2 13.2096L14.2 5.20959C14.5921 4.82023 14.5944 4.18671 14.205 3.79459C13.8156 3.40246 13.1821 3.40023 12.79 3.78959Z"
            fill="#5EA42E"
          />
        </svg>
      </i>
    </button>
  );
};
