import {
  buildCoursePageUrl,
  formatDate,
  extractDataFromUrn
} from '../utils/reviewsUtils';

export const ReviewsEntry = function ({
  entry = {},
  selected,
  handleSelectReview,
  handleShowCommentModal
}) {
  const { courseId, locale } = extractDataFromUrn(entry.urn);

  function handleCheck (ev) {
    handleSelectReview(ev.target.value);
  }

  return (
    <div className={
        `row ${selected.includes(entry.id) && 'selected-row'}`
      }
    >
      <span className='select'>
        <input
          type='checkbox'
          checked={selected.includes(entry.id)}
          value={entry.id}
          onChange={(ev) => handleCheck(ev)}
        />
      </span>
      <span className='date-added'>{formatDate(entry.dateAdded)}</span>
      <span className={`status ${entry.status && entry.status.toLowerCase()}`}>
        {entry.status}
      </span>
      <span className='course-id'>
        <a
          href={buildCoursePageUrl(courseId, locale)}
          target='_blank'
        >
          {courseId}
        </a>
      </span>
      <span className='course-title'>{entry.courseTitle}</span>
      <span className='review'>{entry.review}</span>
      <span className='editor-comments'>
        <button
          className='link-button'
          onClick={() => handleShowCommentModal(entry)}
        >
          {entry.comment && entry.comment !== ''
            ? 'Edit'
            : 'Add'}
        </button>
      </span>
      <span className='ics-id'>{entry.icsId}</span>
      <span className='reviewer-email'>{entry.reviewerEmail}</span>
    </div>
  );
};