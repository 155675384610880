import { BaseModal } from './BaseModal';
import close from '../assets/close-x-icon.svg';

export const ReviewsModal = function ({
  buttonTxt,
  handleBtnClick,
  handleClose,
  children
}) {

  return (
    <BaseModal>
      <div className='modal'>
        <div className='modal-header'>
          <button onClick={handleClose}>
            <img src={close} />
          </button>
        </div>
        <div className='modal-body'>
          {children}
        </div>
        <div className='modal-footer'>
          <button
            className='blue-btn'
            onClick={handleBtnClick}
          >
            {buttonTxt}
          </button>
        </div>
      </div>
    </BaseModal>
  );
};