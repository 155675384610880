import rightArrow from '../assets/right-arrow.svg';
import './css/base-pagination-numbers.scss';

export const BasePaginationNumbers = function({ hits, pageNumber, pageSize, handlePageChange }) {

  // Rounds up to nearest whole number
  const numberOfPages = Math.ceil(hits / pageSize);

  // Creating an array of all page numbers for this hit
  // so we can slice out 4 page numbers to display from
  //the total number of pages array depending on your current page number
  const numberOfPagesIndex = [...Array(numberOfPages).keys()];

  // Calculating start & end slice for which page numbers to display array
  let startingNumber;
  if (pageNumber < 3) {
    startingNumber = 0;
  } else if (pageNumber > numberOfPages - 2) {
    startingNumber = numberOfPages - 4;
  } else {
    startingNumber = pageNumber - 2;
  }

  let endingNumber;
  if (pageNumber === 1 || !pageNumber) {
    endingNumber = 4;
  } else if (pageNumber >= numberOfPages - 2) {
    endingNumber = numberOfPages + 2;
  } else {
    endingNumber = pageNumber + 2;
  }

  // Slicing out 4 page numbers to display from the total number of pages array depending on your current page number
  const slicedNumberOfPages = numberOfPagesIndex.slice(
    startingNumber,
    endingNumber
  );

  let nextOnClick;
  if (!pageNumber) {
    nextOnClick = 1;
  } else if (pageNumber === numberOfPages) {
    nextOnClick = numberOfPages;
  } else {
    nextOnClick = pageNumber + 1;
  }

  return (
    <div className='pagination'>
      <span className='total-pages'>
        Page {pageNumber ? pageNumber : 1} of {numberOfPages}
      </span>

      <div className='page-numbers-container'>
        <button
          tabIndex={pageNumber > 1 ? 0 : -1}
          className='left'
          onClick={() => handlePageChange(pageNumber - 1)}
          disabled={pageNumber <= 1}
          aria-label='Previous results'
        >
          <img src={rightArrow} alt='previous' />{' '}
          <span>Previous</span>
        </button>

        {slicedNumberOfPages.map((pages, index) => (
          <button onClick={() => handlePageChange(pages + 1)} key={index}>
            <span
              className={`page-number-links ${
                pageNumber === pages + 1 ? 'active' : ''
              }`}
            >
              {pages + 1}
            </span>
          </button>
        ))}

        <button
          tabIndex={pageNumber < numberOfPages ? 0 : -1}
          className='right'
          onClick={() => handlePageChange(nextOnClick)}
          disabled={pageNumber === numberOfPages}
          aria-label='Next results'
        >
          <span>Next</span>{' '}
          <img src={rightArrow} alt='next' />
        </button>
      </div>
    </div>
  );
};

export default BasePaginationNumbers;
