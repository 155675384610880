import './css/base-tabs-menu.scss';

export const BaseTabsMenu = function ({ name, label, value, options = [], onSelect }) {
  return (
    <div className="base-tabs-menu" cy={name}>
      <b className="block m-b-8">{label}</b>
      <div className="flex">
        {options.map((option) => (
          <button
            key={option}
            className={`${value === option ? 'tab-selected' : ''} no-border bg-white w100 pointer`}
            onClick={() => onSelect({ key: name, value: option })}
          >
            {option}
          </button>
        ))}
      </div>
    </div>
  );
};
