import './css/confirmation-popup.scss';
import { useSelector } from 'react-redux';
import {ServerErrorModal} from './ServerErrorModal';
export const ConfirmationPopup = function ({handleConfirm, handleDecline, text = {}}) {

  const { errMsg, serverError } = useSelector(state => state.course);

  return (
    <div cy='confirmation-popup' className="confirmation-popup">
      {serverError ? <ServerErrorModal msg={errMsg} close={handleConfirm} /> :
        (<div>
          <p className='text-center' dangerouslySetInnerHTML={{ __html: errMsg }} />
          <div className="actions">
            {text.no && (
              <button className="no-border" onClick={handleDecline}>
                {text.no}
              </button>
            )}
            <button className="no-border" onClick={handleConfirm}>
              {text.yes}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
