import { LIVE_COURSE_PAGE_BASE_URL } from './Constants';

export function buildStatusUpdates (ids, status) {
  const payload = [];
  ids.forEach((id) => {
    payload.push({
      id,
      status
    });
  });
  return payload;
}

export function buildCoursePageUrl (courseId, locale) {
  let url = LIVE_COURSE_PAGE_BASE_URL + courseId;
  locale !== 'en-gb' && (url += `?course-lang=${locale}`);
  return url;
}

export function formatDate (date) {
  const d = new Date(date);
  return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
}

export function formatFilterDate (date) {
  const d = new Date(date);
  return `${d.getFullYear()}/${d.getMonth() + 1}/${d.getDate()}`;
}

export function extractDataFromUrn (urn) {
  const urnParts = urn.split(':');
  return {
    courseId: urnParts[3],
    locale: urnParts[4]
  };
}

export function mapStatus (reviews = []) {
  const unreviewed = [];
  reviews?.forEach((review) => {
    review.status === 'UNREVIEWED' && unreviewed.push(review.id);
  });
  return unreviewed;
}

export function extractIds (data = []) {
  const ids = [];
  data.forEach((entry) => {
    entry.id && ids.push(entry.id);
  });
  return ids;
}