import { BaseAddNewButton, IncludedCourseDetails } from './index';
import './css/courses-included-section.scss';

export const CoursesIncluded = function ({ courses = [], addCourse, deleteCourse }) {
  return (
    <section className="courses-included-section">
      <h2>Courses included</h2>
      {courses.map((course, index) => (
        <IncludedCourseDetails
          key={Math.random() * 1000}
          onDelete={deleteCourse}
          index={index}
          course={course}
        />
      ))}
      <BaseAddNewButton text="Add a new course" cy="add-new-course-btn" onClick={addCourse} />
    </section>
  );
};
