import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';

export const LastUpdated = function ({ dateUpdated, onChange }) {
  return (
    <div cy="last-updated">
      <label htmlFor="updated">
        Last updated <br />
        <DatePicker
          selected={new Date(dateUpdated)}
          dateFormat="d MMM, yyyy"
          onChange={(date) => onChange({ key: 'dateUpdated', value: date })}
        />
      </label>
      <button
        className="flex-center"
        onClick={() => onChange({ key: 'dateUpdated', value: new Date() })}
      >
        Update to today
      </button>
    </div>
  );
};
