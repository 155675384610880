import './css/base-checkbox.scss';

export const BaseCheckbox = function ({ name, label, value, correct, onChange }) {
  return (
    <label
      className={`base-checkbox flex align-center pointer ${value === correct ? 'checked' : ''}`}
      cy={name}
      htmlFor={name}
    >
      <span>{label}</span>
      <input
        name={name}
        id={name}
        value={value}
        checked={value === correct}
        onChange={() => onChange({ value })}
        type="radio"
      />
    </label>
  );
};
