import { useDispatch, useSelector } from 'react-redux';
import './css/included-course-details.scss';
import { fetch_course } from '../store/modules/collection';

export const IncludedCourseDetails = function ({ course, index, onDelete }) {
  const items = ['No.', 'Course ID', 'Title', 'Duration'];
  const courses = useSelector((state) => state.collection.courses);
  const dispatch = useDispatch();

  function handleDelete (ev) {
    /* I have added a nice animation to highlight the deleted course */
    const div = ev.target.parentNode;
    div.style.height = '0';
    div.style.animation = '.2s linear 2 flash';
    setTimeout(() => {
      onDelete(course.moduleId);
    }, 700);
  }

  function handleSubmitId (ev) {
    const courseId = ev.target.value;
    if (ev.key === 'Enter' && !course.title) {
      dispatch(fetch_course({ courseId }));
    }
  }

  return (
    <div className="included-course-details flex" id={`course-${index + 1}`}>
      <div>
        <ul className="flex flex-auto">
          {items.map((val) => (
            <li key={val}>{val}</li>
          ))}
        </ul>
        <ul className="flex" cy={`courses-included-list-${index}`}>
          <li>{index + 1}</li>
          <li>
            <input
              className="wh100"
              value={course.moduleId}
              onChange={() => {}}
              onKeyUp={(ev) => handleSubmitId(ev)}
              type="text"
            />
          </li>
          <li className="course-title">
            <span>{course.isFetching ? 'Fetching course ...' : courses[index]?.title}</span>
            {course.isError && <span>Course id not found</span>}
            {course.isDuplicated && <span>Course duplicate</span>}
          </li>
          <li>
            <span>{courses[index]?.duration}</span>
          </li>
        </ul>
      </div>
      <button className="bg-transparent no-border" onClick={handleDelete}>
        Delete
      </button>
    </div>
  );
};
