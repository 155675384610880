import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { PRE_TEST_QUESTIONS_URL } from '../../utils/Constants';
import { getJwtToken } from '../utils';

/* How to inject the authorization header once for all request? */
axios.interceptors.request.use(
  async function (config) {
    const jwtToken = await getJwtToken();
    config.headers = { Authorization: jwtToken };
    config.params = { filterFields: false };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const initialState = {
  data: {
    questions: [],
    passmark: '0'
  },
  status: 'idle'
};

export const questionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    SET_QUESTIONS: (state, { payload }) => {
      state.data = payload;
    },
    CLEAR_QUESTIONS (state) {
      state.data.questions = [];
      state.data.passmark = '0';
    },
    HANDLE_STATUS: (state, { payload }) => {
      state.status = payload;
    },
    SET_QUESTION_PROP (state, { payload: { id, key, value } }) {
      const qIndex = state.data.questions.findIndex((obj) => obj.id === id);
      state.data.questions[qIndex][key] = value;
    },
    DELETE_LAST_ANSWER (state, { payload: { id } }) {
      const qIndex = state.data.questions.findIndex((obj) => obj.id === id);
      state.data.questions[qIndex].options.splice(-1);
    },
    ADD_ANSWER (state, { payload: { id } }) {
      /* answers options are declared alphabetically in the value field,
         so when adding a new answer we need to have
         the value of the next alphabetical letter (check questions-get.json in the cypress /features folder)
      */
      const qIndex = state.data.questions.findIndex((obj) => obj.id === id);
      const answers = state.data.questions[qIndex].options;
      const lastAnswerLetter = answers.length ? answers[answers.length - 1].value : '';
      /* the next line increment the alphabet letter */
      const nextAnswerLetter =
        lastAnswerLetter.substring(0, lastAnswerLetter.length - 1) +
        String.fromCharCode(lastAnswerLetter.charCodeAt(lastAnswerLetter.length - 1) + 1);
      state.data.questions[qIndex].options.push({
        name: '',
        value: answers.length ? nextAnswerLetter : 'A'
      });
    },
    UPDATE_ANSWER_TEXT (state, { payload: { id, index, value } }) {
      // id = question id, index = answer index
      const qIndex = findIndexByProp(state.data.questions, 'id', id);
      state.data.questions[qIndex].options[index].name = value;
    },
    SELECT_CORRECT_ANSWER (state, { payload: { id, value } }) {
      // id = question id, index = answer index
      const qIndex = findIndexByProp(state.data.questions, 'id', id);
      state.data.questions[qIndex].answer = value;
    },
    SET_PASSMARK (state, { payload }) {
      state.data.passmark = payload;
    },
    ADD_NEW_QUESTION (state) {
      const question = {
        id: state.data.questions?.length + 1,
        questionIntro: '',
        question: '',
        options: [
          { name: '', value: 'A' },
          { name: '', value: 'B' },
          { name: '', value: 'C' }
        ],
        answer: '',
        tag: '',
        explanation: ''
      };
      state.data.questions.push(question);
    },
    DELETE_LAST_QUESTION (state) {
      state.data.questions = state.data.questions.slice(0, -1);
    }
  }
});

export const {
  SET_QUESTIONS,
  CLEAR_QUESTIONS,
  HANDLE_STATUS,
  SET_QUESTION_PROP,
  SET_PASSMARK,
  ADD_ANSWER,
  DELETE_LAST_ANSWER,
  UPDATE_ANSWER_TEXT,
  SELECT_CORRECT_ANSWER,
  ADD_NEW_QUESTION,
  DELETE_LAST_QUESTION
} = questionsSlice.actions;

export const get_questions = ({ id, language = 'en_GB' }) =>
  async function (dispatch) {
    try {
      dispatch(HANDLE_STATUS('loading'));
      const url = PRE_TEST_QUESTIONS_URL.replace('{courseId}', id).replace(
        '{courseLanguage}',
        language
      );
      const { data } = await axios.get(url);
      dispatch(SET_QUESTIONS(data));
      dispatch(HANDLE_STATUS('success'));
      setTimeout(() => {
        dispatch(HANDLE_STATUS('idle'));
      }, 1000);
    } catch (e) {
      dispatch(HANDLE_STATUS('error'));
    }
  };
export const save_questions = ({ id, language }) =>
  async function (dispatch, getState) {
    try {
      dispatch(HANDLE_STATUS('saving'));
      const {
        questions: { data }
      } = getState();
      const url = PRE_TEST_QUESTIONS_URL.replace('{courseId}', id).replace(
        '{courseLanguage}', language);
      await axios.put(url, data);
      dispatch(HANDLE_STATUS('success'));
      setTimeout(() => {
        dispatch(HANDLE_STATUS('idle'));
      }, 3000);
    } catch (e) {
      dispatch(HANDLE_STATUS('error'));
    }
  };

export default questionsSlice.reducer;

/* util functions */
function findIndexByProp (array, prop, value) {
  return array.findIndex((obj) => obj[prop] === value);
}
