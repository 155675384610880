import { useEffect, useState } from 'react';
import { nextCharValue } from '../utils/utils';
import {
  fetchChapters,
  fetchDoi,
  fetchQuestions,
  updateChapters,
  updateDoi,
  updateQuestions
} from './api';
import { useDispatch } from 'react-redux';
import { fetch_chapters } from './modules/chapters';

export const useChapters = function (id, language) {
  const [chapters, setChapters] = useState({
    data: [],
    state: 'idle',
    error: null
  });
  const [savedStatus, setSavedStatus] = useState('idle');
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      setChapters((prev) => ({ ...prev, state: 'loading' }));
      fetchChapters({ id, language })
        .then((data) => {
          setChapters((prev) => ({ ...prev, data, state: 'success' }));
        })
        .catch((err) => setChapters({ data: [], state: 'error', error: err }));
      dispatch(fetch_chapters({ id, language }));
    }
  }, [id, language]);

  function addChapter () {
    setChapters((prev) => ({
      ...prev,
      data: [...prev.data, { id: prev.data.length + 1, title: '', url: '' }]
    }));
  }

  async function saveChaptersToDB (id, language) {
    setSavedStatus('loading');
    try {
      await updateChapters({ id, language, chapters: chapters.data });
      setSavedStatus('success');
    } catch (err) {
      setSavedStatus('error');
    }
  }

  return {
    ...chapters,
    isError: chapters.state === 'error',
    isLoading: chapters.state === 'loading',
    isSuccess: chapters.state === 'success',
    addChapter,
    saveChaptersToDB,
    savedStatus
  };
};

export const useQuestions = function (id, language) {
  const [questions, setQuestions] = useState({
    data: { questions: [], passmark: '0' },
    state: 'idle',
    error: null
  });
  const [savedStatus, setSavedStatus] = useState('idle');

  useEffect(() => {
    setQuestions((prev) => ({ ...prev, state: 'loading' }));
    fetchQuestions({ id, language })
      .then((data) => {
        setQuestions((prev) => ({ ...prev, data, state: 'success' }));
      })
      .catch((error) => {
        const questionsArray = [];
        for (let i = 0; i < 5; i++) {
          questionsArray.push({
            id: questionsArray.length + 1,
            questionIntro: '',
            question: '',
            options: [
              { name: '', value: 'A' },
              { name: '', value: 'B' },
              { name: '', value: 'C' },
              { name: '', value: 'D' },
              { name: '', value: 'E' }
            ],
            answer: '',
            tag: '',
            explanation: ''
          });
        }
        setQuestions(() => ({
          data: { questions: questionsArray, passmark: '0' },
          error,
          state: 'error'
        }));
      });
  }, [id, language]);

  function updatePassmark (passmark) {
    setQuestions({ ...questions, data: { ...questions.data, passmark } });
  }

  function addQuestion () {
    const questionsCopy = [...questions.data.questions];
    questionsCopy.push({
      id: questionsCopy.length + 1,
      questionIntro: '',
      question: '',
      options: [
        { name: '', value: 'A' },
        { name: '', value: 'B' },
        { name: '', value: 'C' },
        { name: '', value: 'D' },
        { name: '', value: 'E' }
      ],
      answer: '',
      tag: '',
      explanation: ''
    });
    setQuestions({
      ...questions,
      data: { ...questions.data, questions: questionsCopy }
    });
  }

  function deleteLastQuestion () {
    const updatedQuestions = questions.data.questions.slice(0, -1);
    setQuestions({
      ...questions,
      data: { ...questions.data, questions: updatedQuestions }
    });
  }

  // Could be used to update any field (e.g. question/explanation/tag)
  function updateQuestion ({ id, value, fieldName }) {
    const questionsCopy = [...questions.data.questions];
    const updateIndex = questionsCopy.findIndex((o) => o.id === id);
    // TODO is the tag supposed to be String or Number
    questionsCopy[updateIndex][fieldName] = value;
    setQuestions({
      ...questions,
      data: { ...questions.data, questions: questionsCopy }
    });
  }

  function addAnswer (id) {
    // Deep copy as we update embedded objects
    const questionsCopy = JSON.parse(JSON.stringify(questions.data.questions));
    const updateIndex = questions.data.questions.findIndex((o) => o.id === id);
    const options = questionsCopy[updateIndex]['options'];

    const newOptionValue =
      options.length > 0 ? nextCharValue(options[options.length - 1].value) : 'A';
    options.push({ name: '', value: newOptionValue });
    setQuestions({
      ...questions,
      data: { ...questions.data, questions: questionsCopy }
    });
  }

  function deleteLastAnswer (id) {
    // Deep copy as we update embedded objects
    const questionsCopy = JSON.parse(JSON.stringify(questions.data.questions));
    const updateIndex = questions.data.questions.findIndex((o) => o.id === id);
    questionsCopy[updateIndex]['options'].pop();
    setQuestions({
      ...questions,
      data: { ...questions.data, questions: questionsCopy }
    });
  }

  function updateAnswer ({ id, optionId, value }) {
    // Deep copy as we update embedded objects
    const questionsCopy = JSON.parse(JSON.stringify(questions.data.questions));
    const questionIndex = questionsCopy.findIndex((o) => o.id === id);
    const optionIndex = questionsCopy[questionIndex]['options'].findIndex(
      (o) => o.value === optionId
    );
    questionsCopy[questionIndex]['options'][optionIndex]['name'] = value;
    setQuestions({
      ...questions,
      data: { ...questions.data, questions: questionsCopy }
    });
  }

  function setCorrectAnswer ({ id, optionValue }) {
    const questionsCopy = [...questions.data.questions];
    const updateIndex = questionsCopy.findIndex((o) => o.id === id);
    questionsCopy[updateIndex]['answer'] = optionValue;
    setQuestions({
      ...questions,
      data: { ...questions.data, questions: questionsCopy }
    });
  }

  async function saveQuestionsToDB (id, language) {
    setSavedStatus('loading');
    try {
      await updateQuestions({ id, language, questions: questions.data });
      setSavedStatus('success');
    } catch (err) {
      setSavedStatus('error');
    }
  }

  return {
    ...questions,
    isError: questions.state === 'error',
    isLoading: questions.state === 'loading',
    isSuccess: questions.state === 'success',
    updatePassmark,
    addQuestion,
    deleteLastQuestion,
    updateQuestion,
    addAnswer,
    deleteLastAnswer,
    updateAnswer,
    saveQuestionsToDB,
    setCorrectAnswer,
    savedStatus
  };
};

export function useDoi (id, language) {
  const [doi, setDoi] = useState();
  const [savedStatus, setSavedStatus] = useState('idle');
  const [isDoiEditable, setIsDoiEditable] = useState(false);

  useEffect(() => {
    fetchDoi({ id, language })
      .then((data) => setDoi(Array.isArray(data) ? data[0]?.externalId : data.externalId))
      // TODO Handle that error or ignore?
      .catch((err) => console.log(err));
  }, [id, language]);

  async function saveDoiToDB (externalId) {
    setSavedStatus('loading');
    try {
      await updateDoi({ externalId, id, language });
      setSavedStatus('success');
    } catch (err) {
      setSavedStatus('error');
    }
  }

  return {
    doi,
    updateDoi: setDoi,
    saveDoiToDB,
    isDoiEditable,
    setIsDoiEditable,
    savedStatus
  };
}
