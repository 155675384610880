import React, { useState } from 'react';
import { Grid, TextareaAutosize, Radio, Button } from '@material-ui/core';
import { ORGANISATIONS_URL } from '../../utils/Constants';
import { useHistory } from 'react-router-dom';
import './organisation.scss';

const NewOrganisation = () => {
  const [active, setActive] = useState(true);
  const [result, setResult] = useState('');

  const [enteredOrganisationName, setEnteredOrganisationName] = useState('');
  const [enteredOrganisationStatement, setEnteredOrganisationStatement] = useState('');

  const failedSaveOrganisationMsg = 'Failed to save organisation';
  const history = useHistory();

  const returnToOrganisationsListPage = () => {
    history.push('/organisations');
  };

  const saveAndSubmitNewOrganisation = () => {
    const newOrgJson = {
      name: enteredOrganisationName,
      logo: '',
      organisationStatement: enteredOrganisationStatement,
      isActive: active
    };
    postNewOrganisation(newOrgJson);
  };

  const postNewOrganisation = (data) => {
    fetch(ORGANISATIONS_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then((response) => {
        if (response.ok) {
          setResult(failedSaveOrganisationMsg);
          returnToOrganisationsListPage();
        } else {
          setResult(failedSaveOrganisationMsg);
        }
      })
      .catch(() => {
        setResult(failedSaveOrganisationMsg);
      });
  };

  const swapOrgStatus = () => setActive((prev) => !prev);

  const handleOrganisationName = (event) => {
    setEnteredOrganisationName(event.target.value);
  };
  const handleOrganisationStatement = (event) => {
    setEnteredOrganisationStatement(event.target.value);
  };

  return (
    <Grid container className="new-org">
      <Grid container>
        <h2>Add new organisation</h2>
      </Grid>

      <Grid container>
        <p>Organisation Name: </p>
        <TextareaAutosize
          id="organisation-name"
          placeholder="Enter Organisation Name"
          onChange={(event) => handleOrganisationName(event)}
        />
      </Grid>
      <Grid container>
        <p>Organisation Statement: </p>
        <TextareaAutosize
          id="organisation-statement"
          label="Enter Statement"
          placeholder="Enter Organisation Statement"
          onChange={(event) => handleOrganisationStatement(event)}
        />
      </Grid>
      <Grid container>
        <p>Organisation Status:</p>
        <Radio
          checked={active}
          onChange={() => {
            swapOrgStatus();
          }}
          value={active}
          name="radio-org-status"
        />{' '}
        <p className="radio">Active</p>
        <Radio
          checked={!active}
          onChange={() => {
            swapOrgStatus();
          }}
          value={active}
          name="radio-org-status"
        />{' '}
        <p className="radio">Inactive</p>
      </Grid>
      <Grid container>
        <Button
          variant="contained"
          className="primary-button"
          onClick={() => {
            saveAndSubmitNewOrganisation();
          }}
          disabled={
            enteredOrganisationName.trim().length === 0 ||
            enteredOrganisationStatement.trim().length === 0
          }
        >
          Save Organisation
        </Button>
        <p>{result}</p>
      </Grid>
    </Grid>
  );
};

export default NewOrganisation;
