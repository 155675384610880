import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { useLocation } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { ACCREDITATIONS_URL, ORGANISATIONS_URL } from '../../utils/Constants';
import { useHistory } from 'react-router-dom';
import './NewAccreditation.css';

const NewAccreditation = () => {
  const [result, setResult] = useState('');
  const [organisation, setOrganisation] = useState({});

  const [accreditationCpdMins, setAccreditationCpdMins] = useState('');
  const [accreditationCpdPoints, setAccreditationCpdPoints] = useState('');

  const [accreditationStatement, setAccreditationStatement] = useState('');
  const failedSaveAccMsg = 'Failed to save accreditation';
  const history = useHistory();
  const orgId = new URLSearchParams(useLocation().search).get('organisationId');

  useEffect(() => {
    fetch(ORGANISATIONS_URL + '/' + orgId)
      .then((response) => response.json())
      .then((json) => setOrganisation(json))
      .catch((err) => console.error(err));
  }, []);

  const returnToOrganisationPage = () => {
    history.push('/organisation/' + orgId);
  };

  const saveAndSubmitNewAccreditation = () => {
    const newAccreditationJson = {
      organisationId: orgId,
      cpdPoints: accreditationCpdPoints,
      cpdMinutes: accreditationCpdMins,
      accreditationStatement: accreditationStatement
    };
    postNewAccreditation(newAccreditationJson);
  };

  const postNewAccreditation = (data) => {
    fetch(ACCREDITATIONS_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then((response) => {
        if (response.ok) {
          returnToOrganisationPage();
        } else {
          setResult(failedSaveAccMsg);
        }
      })
      .catch(() => {
        setResult(failedSaveAccMsg);
      });
  };

  const handleAccreditationStatement = (event) => {
    setAccreditationStatement(event.target.value);
  };

  const handleCpdMinutes = (event) => {
    const minutes = event.target.value;
    if (Number(minutes) || minutes === '') {
      setAccreditationCpdMins(minutes);
    }
  };

  const handleCpdPoints = (event) => {
    const points = event.target.value;
    if (Number(points) || points === '') {
      setAccreditationCpdPoints(points);
    }
  };

  return (
    <Grid container className="new-accreditation">
      <Grid container>
        <h2>Add New Accreditation for {organisation.name}</h2>
      </Grid>

      <Grid container>
        <p>Accreditation CPD Minutes: </p>
        <TextField
          id="accreditation-cpd-minutes"
          label="Minutes"
          variant="outlined"
          value={accreditationCpdMins}
          onChange={(event) => handleCpdMinutes(event)}
        />
      </Grid>
      <Grid container>
        <p>Accreditation CPD Points: </p>
        <TextField
          id="accreditation-cpd-points"
          label="Points"
          variant="outlined"
          value={accreditationCpdPoints}
          onChange={(event) => handleCpdPoints(event)}
        />
      </Grid>
      <Grid container>
        <p>Accreditation Statement: </p>
        <TextareaAutosize
          id="organisation-statement"
          label="Enter Statement"
          placeholder="Enter Accreditation Statement"
          onChange={(event) => handleAccreditationStatement(event)}
        />
      </Grid>

      <Grid container className="result">
        <Button
          variant="contained"
          className="primary-button"
          onClick={() => {
            saveAndSubmitNewAccreditation();
          }}
          disabled={
            accreditationStatement.trim().length === 0 ||
            accreditationCpdPoints.length === 0 ||
            setAccreditationCpdMins.length === 0
          }
        >
          Save Accreditation
        </Button>
        <p>{result}</p>
      </Grid>
    </Grid>
  );
};

export default NewAccreditation;
