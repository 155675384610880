import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  ADD_COURSE,
  DELETE_COURSE_INCLUDED,
  get_collection,
  SET_COLLECTION_PROP,
  HANDLE_ADD_DELETE_AUTHOR,
  HANDLE_AUTHOR,
  CREATE_NEW_COLLECTION,
  save_collection,
  save_new_collection
} from '../store/modules/collection';
import {
  BaseSideMenu,
  CollectionInformation,
  CollectionKeyDetails,
  CollectionAuthors,
  CollectionLearningObjectives,
  CoursesIncluded
} from '../components';
import linkIcon from '../assets/link-external.svg';
import './css/collection-edit.scss';
import '../components/css/content-editor.scss';

export const quickLinksData = {
  title: 'Quick Links',
  options: [
    'Key details',
    'Courses included',
    'Collection information',
    'Learning objectives',
    'Authors'
  ]
};
export const actions = {
  title: 'Course actions',
  options: [
    {
      name: 'Create a new collection',
      action: 'create',
      cy: 'create-new-collection'
    },
    {
      name: 'Duplicate collection',
      action: 'duplicate',
      cy: 'duplicate-course'
    },
    {
      name: 'Open live site',
      icon: linkIcon,
      action: 'open_live_site'
    }
  ]
};

function CollectionEdit() {
  const dispatch = useDispatch();
  // when creating a new collection we will need to reset the collectionId param, so we don't destructure it
  let params = useParams();
  const {
    location: { pathname }
  } = useHistory();
  const courses = useSelector((state) => state.collection.courses);
  const { objectives, contributors } = useSelector((state) => state.collection.data);
  const { status } = useSelector((state) => state.collection);

  useEffect(() => {
    // if the route contains collection/new then trigger this, else get collection data
    if (window.location.pathname.includes('/collection/new')) {
      params.collectionId = null;
      dispatch(CREATE_NEW_COLLECTION());
    } else {
      dispatch(get_collection(params));
    }
  }, []);

  function handleMenuAction(action) {
    switch (action) {
      case 'create':
        // reset the collectionId because creating a new collection is conditioned by (view handleSuccess fn)
        params.collectionId = null;
        dispatch(CREATE_NEW_COLLECTION());
        window.history.replaceState({}, '', '/collection/new');
        break;
      case 'duplicate':
        // here we will have to add the data logic to duplicate a course
        break;
      case 'open_live_site':
        window.open(`https://new-learning.bmj.com${pathname}`, '_blank');
        break;
    }
  }

  function addCourse() {
    dispatch(ADD_COURSE());
  }
  function deleteCourse(id) {
    dispatch(DELETE_COURSE_INCLUDED(id));
  }
  function handleLearningObjectives({ objectives }) {
    dispatch(SET_COLLECTION_PROP({ key: 'objectives', value: objectives }));
  }

  function handleAuthorUpdate(payload) {
    dispatch(HANDLE_AUTHOR(payload));
  }
  function handleAddDeleteAuthor(action) {
    dispatch(HANDLE_ADD_DELETE_AUTHOR(action));
  }

  async function handleSave() {
    params.collectionId ? await dispatch(save_collection()) : await dispatch(save_new_collection());
  }

  return (
    <main className="collection-edit content-editor">
      <BaseSideMenu
        quickLinks={quickLinksData}
        status={status}
        actions={actions}
        handleSave={handleSave}
        handleAction={handleMenuAction}
      />

      <CollectionKeyDetails />

      <CoursesIncluded courses={courses} addCourse={addCourse} deleteCourse={deleteCourse} />

      <CollectionInformation />

      <CollectionLearningObjectives
        id="learning-objectives"
        list={objectives}
        updateCourseDetails={handleLearningObjectives}
      />
      <CollectionAuthors
        authorsList={contributors}
        handleAuthorUpdate={handleAuthorUpdate}
        handleAddDeleteAuthor={handleAddDeleteAuthor}
      />
    </main>
  );
}

export default CollectionEdit;
