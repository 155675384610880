import './css/base-button.scss';

export const BaseButton = function ({ name, cy, text, onClick, children }) {
  return (
    <button className="base-button no-border pointer" name={name} cy={cy || name} onClick={onClick}>
      {text}
      <span dangerouslySetInnerHTML={{ __html: children.type() }} />
    </button>
  );
};
